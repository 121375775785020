import React, { useState, useEffect, useRef } from "react";
import PhoneValidation from "./PhoneValidation";
import Tooltip from "./Tooltip";
const useRequired = ({ required = false, regex = false, value = "" }) => {
  const [messageTooltip, setMessageTooltip] = useState("");
  const [showTooltip, setShowTooltip] = useState("");
  const [validInput, setValidInput] = useState(true);
  const [valorInput, setValorInput] = useState("");
  const focusedStart = useRef(false);
  useEffect(() => {
    if (value) {
      setValorInput(value);
    }
  }, []);

  const handleFocusRequired = (focused) => {
    if (!required) return;
    if (!focusedStart.current && focused) {
      focusedStart.current = true;
    } else if (!valorInput && focusedStart.current && !focused) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  };
  const handleChangeValue = (value) => {
    if (!required) return;
    const valorInputCurrent = value?.target?.value;
    setValorInput(valorInputCurrent);
    if (regex?.length > 0) {
      for (const element of regex) {
        let reg = new RegExp(element.value);
        if (
          element.coincidencia
            ? !reg.test(valorInputCurrent)
            : reg.test(valorInputCurrent)
        ) {
          setShowTooltip(true);
          setMessageTooltip(element.message);
          setValidInput(false);
          return;
        }
      }
    }
    setShowTooltip(false);
    setValidInput(true);
    setMessageTooltip("");
  };

  return {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  };
};
export default function InputPhone({
  onChange,
  value,
  label,
  id,
  name,
  type,
  valid,
  rounded,
  tooltip,
  required = false,
  regex = "",
}) {
  const [focused, setFocused] = useState(false);
  const inputError = !valid && value !== "";
  const handleFocus = () => {
    setFocused(!focused);
  };
  const handleChange = (params) => {
    onChange(params);
    handleChangeValue(params);
  };
  const {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  } = useRequired({ required, regex, value });
  useEffect(() => {
    handleFocusRequired(focused);
  }, [focused, handleFocusRequired]);
  return (
    <div className="position-relative mt-3 container-fluid p-0">
      <div
        className={`position-absolute pe-none 
        ${rounded ? "input_label_small" : "input_label"}
        ${inputError || !validInput ? `text-danger` : ``}
      ${
        !(focused || value !== "")
          ? "input_label_inside_input_phone"
          : "input_label_outside_input_phone"
      }
      `}
      >
        {label}
      </div>
      <div
        className={`input_phone_container input-group border ${
          inputError || !validInput
            ? `border-danger text-danger`
            : `border-dark`
        }`}
      >
        <span
          className={`input-group-text 
          phone_input_label 
          bg-transparent 
          border 
          border-0 
          position-relative
          fw-bold
          ${
            inputError || !validInput
              ? `phone_input_label_error text-danger`
              : `phone_input_label_neutral`
          }
        `}
          id={`label-${id}`}
        >
          +52
        </span>
        <input
          autoComplete="off"
          id={id}
          name={name}
          type={type}
          onFocus={handleFocus}
          onBlur={handleFocus}
          onChange={handleChange}
          value={value}
          maxLength={10}
          className={`input_phone form-control bg-transparent
         ${inputError || !validInput ? `text-danger` : ``}
          ${rounded ? "rounded-pill" : ""} `}
          aria-describedby={`label-${id}`}
        />
      </div>
      {tooltip && <PhoneValidation valid={valid} />}
      {showTooltip && (
        <Tooltip valid={validInput} messageIncorrect={messageTooltip} />
      )}
    </div>
  );
}
