import React, { useState, useEffect } from "react";

export default function Tooltip({ messageIncorrect, messageNeutral, valid }) {
  return (
    <>
      {!valid && (
        <div className="container email_tooltip_validator">
          <p
            style={{ ...(!valid ? { color: "red" } : {}) }}
            className={`
            email_tootlip_validator_text 
            email_tootlip_validator_text_point
            ${
              "email_tootlip_validator_text_error"
              /* : "email_tootlip_validator_text_neutral"*/
            }
            `}
          >
            {messageIncorrect}
          </p>
        </div>
      )}
    </>
  );
}
