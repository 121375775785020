import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Slider from '@mui/material/Slider';
import { useIsOpen, useOutsideClick, useUpdatePosition } from './hooks';

function valuetext(value) {
  return `${value}°C`;
}

const formatValueLabel = (value) => {
  return `${value / 1000}`; // Aquí puedes personalizar el formato
};

const OptionContainer = ({
  targetRef,
  position,
  options,
  valueKey,
  handleChange,
  labelKey,
  customStyles,
  label,
  value,
  min,
  max,
  step,
  formatValueLabelCustom,
  ...props
}) => {
  return createPortal(
    <div
      ref={targetRef}
      className={`${
        props.rounded || customStyles
          ? 'input_select_option_container_status'
          : 'input_select_option_container_status'
      } shadow`}
      style={{ ...position, minWidth: '275px' }}
    >
      <div
        className={`d-flex justify-content-start input_select_score_option_small_blue z-3`}
      >
        {label}
      </div>
      <div
        className={`d-flex justify-content-start
          input_select_score_option_small z-3 px-3`}
      >
        <Slider
          getAriaLabel={() => 'Minimum distance'}
          value={value.length === 0 ? [min, max] : value}
          onChange={handleChange}
          valueLabelDisplay='auto'
          getAriaValueText={valuetext}
          disableSwap
          valueLabelFormat={
            formatValueLabelCustom ? formatValueLabelCustom : formatValueLabel
          }
          min={min}
          max={max}
          {...(step ? { step: step } : {})}
          defaultValue={[min, max]}
        />
      </div>
    </div>,
    document.getElementById('root'),
  );
};

const useSelect = ({ handleSetValue, id }) => {
  const { isOpen, setIsOpen, handleClick } = useIsOpen();
  const { position, containerRef, updatePosition } = useUpdatePosition();
  const { targetRef } = useOutsideClick({ containerRef, setIsOpen });

  const handleChange = (selectedValue) => {
    handleSetValue({ field: id, value: selectedValue });
  };

  useEffect(() => {
    updatePosition();
  }, [isOpen, updatePosition]);

  return {
    isOpen,
    handleChange,
    handleClick,
    containerRef,
    targetRef,
    position,
  };
};

const availableArrows = {
  dark: '/images/dicio/arrow_down.webp',
  white: '/images/icons/arrow_down_white.png',
};
export default function ScoreInput({
  error,
  value,
  handleSetValue,
  touched,
  id,
  label,
  options,
  valueKey,
  labelKey,
  customStyles,
  arrow = 'dark',
  arrowWidth = 10,
  arrowPosition = {},
  max_width,
  icon = null,
  step,
  direction = {
    bottom: '0',
    left: '0',
    transform: 'translateY(101%)',
  },
  min = 0,
  max = 0,
  formatValueLabelCustom = false,
  ...props
}) {
  const {
    isOpen,
    handleChange,
    handleClick,
    containerRef,
    targetRef,
    position,
  } = useSelect({
    handleSetValue,
    options,
    valueKey,
    id,
    value,
  });
  const inputError = !!(value !== '' && error);
  const inputValid = !!(value !== '' && !error);
  return (
    <div
      ref={containerRef}
      className={`container-fluid p-0 ${
        customStyles ? '' : 'mt-3'
      } position-relative`}
      style={{
        marginLeft: '0',
        ...(max_width ? { maxWidth: `${max_width}px` } : {}),
      }}
    >
      <div
        className={`input_select  d-flex overflow-x-hidden justify-content-start ${
          props.rounded ? 'input_select_small rounded-pill' : 'input_select'
        }
        ${
          customStyles
            ? ''
            : ` border ${
                inputError
                  ? `border-danger`
                  : props.rounded
                  ? 'border_for_small_inputs'
                  : `border-black`
              }`
        } `}
        onClick={handleClick}
        style={customStyles ? customStyles : {}}
      >
        <span
          className='overflow-hidden'
          style={{
            maxWidth: `${max_width - 20}px`,
            color: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            lineHeight: 'inherit',
          }}
        >
          {label}
        </span>
      </div>
      {icon ? (
        icon
      ) : (
        <img
          src={availableArrows[arrow]}
          width={arrowWidth}
          height={'auto'}
          className={`${
            props.rounded ? 'input_select_icon_small' : 'input_select_icon'
          } ${
            isOpen
              ? props.rounded
                ? 'input_select_icon_open_small'
                : 'input_select_icon_open'
              : ''
          }`}
          alt='Select icon arrow'
          style={{
            cursor: 'pointer',
            width: `${arrowWidth}px`,
            ...arrowPosition,
          }}
          onClick={handleClick}
        />
      )}
      {isOpen && (
        <OptionContainer
          targetRef={targetRef}
          position={position}
          handleChange={(event, newValue, activeThumb) => {
            handleChange(newValue);
          }}
          customStyles={customStyles}
          label={label}
          value={value}
          min={min}
          max={max}
          step={step}
          formatValueLabelCustom={formatValueLabelCustom}
          {...props}
        />
      )}
    </div>
  );
}
