/*Se agregan los diferentes endpoint y peticiones a servcios de BACK*/
import axios from 'axios';
import qs from 'qs';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { toUtcTimeZone, translateTransactionsSerach, base64ToBlob } from "./data";
import { saveAs } from 'file-saver';
import { SingletonAPI } from "./singletonApi";
const baseEndpoints = {
  pagination_size: "10",
  limit_export_user: "100",
  nodo_name: "Consorcio",
  valid_email: "https://api.devdicio.net:5501/user/valid-email",
  login: "https://api.devdicio.net:5501/identity/login",
  login_two_factor: "https://api.devdicio.net:5501/user/login/2fa",
  refresh_token: "https://api.devdicio.net:5501/identity/refresh",
  get_all_users: "https://api.devdicio.net:5501/user/get-users",
  export_all_users: "https://api.devdicio.net:5501/user/export-pdf",
  get_all_roles: "https://api.devdicio.net:5501/role/get-roles",
  delete_user: "https://api.devdicio.net:5501/user/delete-user/",
  update_user: "https://api.devdicio.net:5501/user/update-user/",
  create_user: "https://api.devdicio.net:5501/user/register",
  getUserInfo: "https://api.devdicio.net:5501/user/get-user?idOrEmail=",
  forgot_password: "https://api.devdicio.net:5501/user/forgort-password",
  activate_user: "https://api.devdicio.net:5501/user/activate-user/",
  reset_forgotten_password: "https://api.devdicio.net:5501/user/reset-password",
  completeRegistro: "https://api.devdicio.net:5501/user/complete-registration",
  obs_file: "https://api.devdicio.net:8444/v1/sec_dev_file",
  linkObsFile: "https://api.devdicio.net:8444/v1/sec_dev_link?bucket=${bucket}",
  enableTwoFactor: "https://api.devdicio.net:5501/twofactor/enable2fa",
  disableTwoFactor: "https://api.devdicio.net:5501/twofactor/disable2fa",
  sendCode2faenable: "https://api.devdicio.net:5501/twofactor/enable2fa",
  sendCode2fadisable: "https://api.devdicio.net:5501/twofactor/disable2fa",
  resendCodeTwoFactor: "https://api.devdicio.net:5501/twofactor/resendcode",
  change_password: "https://api.devdicio.net:5501/user/change-password",
  get_management_dashboard: "https://api.devdicio.net:6796/generar_dashboard",
  get_management_error: "https://api.devdicio.net:6796/obtener-registros-errores?hash_carga=",
  get_all_privileges: "https://api.devdicio.net:5501/privileges",
  create_rol: "https://api.devdicio.net:5501/role/add-role",
  add_rol_to_user: "https://api.devdicio.net:5501/role/add-user-roles",
  get_all_logs: "https://api.devdicio.net:5501/api/bitacora",
  delete_rol_to_user: "https://api.devdicio.net:5501/role/delete-user-rol/user",
  get_data_charts_node: "https://api.devdicio.net:6880/api/getStatisticsNodo",
  get_statistics_node: "https://api.devdicio.net:6880/api/getMetricsNodo",
  get_statistics_consorcio: "https://api.devdicio.net:6880/api/getStatisticsGeneral",
  get_charts_data_consorcio: "https://api.devdicio.net:6880/api/getInfoLastAllNodos",
  get_chart_pie_consorcio: "https://api.devdicio.net:6880/api/getInfoTransactionsPie",
  get_info_node: "https://api.devdicio.net:6790/api/getInfoNodo",
  get_node_metrics: "https://api.devdicio.net:6790/api/getMetricsCargaInfo",
  get_allDataTransactions: "https://api.devdicio.net:6796/valid-info/",
  get_node_info: "https://api.devdicio.net:6796/getStatusNodos/",
  event_logs_catalog: "https://api.devdicio.net:5501/api/bitacora/catalogoseventos",
  area_catalogo: "https://api.devdicio.net:5501/areas",
  posicion_catalogo: "https://api.devdicio.net:5501/position",
  status_catalog: "https://api.devdicio.net:5501/user/status",
  get_info_carga_db: "https://api.devdicio.net:6796/carga-info/",
  get_nodes_catalog: "https://api.devdicio.net:6796/nodo/",
  get_bitacora_pdf: "https://api.devdicio.net:6501/api/bitacora/exportpdf?orderBy=FechaHora&ascending=true"
};

export let endpoints = null;
let loadEndpointsPromise = null; 

export const loadEndpoints = async () => {
  // Si ya se están cargando los endpoints, devuelve la misma promesa
  if (loadEndpointsPromise) {
    return loadEndpointsPromise;
  }

  // Si los endpoints ya están disponibles, devuélvelos inmediatamente
  if (endpoints) {
    return endpoints;
  }

  // Creamos la promesa para cargar los endpoints
  loadEndpointsPromise = new Promise(async (resolve, reject) => {
    let requestedEndpoints = null;
    let response = null;

    try {
      // Intentar cargar config desde public/config.json
      response = await fetch(`${window.location.origin}/public/config.json`);
      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          requestedEndpoints = await response.json();
          console.info('Archivo de configuración cargado correctamente desde public/config.json');
        } else {
          throw new Error("El archivo de configuración no es un JSON válido");
        }
      } else {
        throw new Error("No se pudo cargar el archivo desde public/config.json");
      }
    } catch (error) {
      console.warn('Fallo al cargar desde public/config.json:', error);

      // Intentar cargar config sin el path public
      try {
        response = await fetch(`${window.location.origin}/config.json`);
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            requestedEndpoints = await response.json();
            console.info('Archivo de configuración cargado correctamente desde config.json');
          } else {
            throw new Error("El archivo de configuración no es un JSON válido");
          }
        } else {
          throw new Error("No se pudo cargar el archivo desde config.json");
        }
      } catch (error) {
        console.error('Fallo al cargar desde config.json:', error);

        // Asignar baseEndpoints como fallback
        requestedEndpoints = baseEndpoints;
        console.error('Usando configuración por defecto');
      }
    }

    // Asignar los valores a endpoints
    endpoints = {
      pagination_size: requestedEndpoints.REACT_APP_CONSTANT_PAGINATION_SIZE,
      limit_export_user: requestedEndpoints.REACT_APP_CONSTANT_LIMIT_EXPORT_USER,
      nodo_name: requestedEndpoints.REACT_APP_CONSTANT_NAME_NODO,
      valid_email: requestedEndpoints.REACT_APP_SERVICE_VALID_EMAIL,
      login: requestedEndpoints.REACT_APP_SERVICE_LOGIN,
      login_two_factor: requestedEndpoints.REACT_APP_SERVICE_LOGIN_TWO_FACTOR,
      refresh_token: requestedEndpoints.REACT_APP_SERVICE_REFRESH_TOKEN,
      get_all_users: requestedEndpoints.REACT_APP_SERVICE_GET_ALL_USERS,
      export_all_users: requestedEndpoints.REACT_APP_SERVICE_EXPORT_ALL_USERS,
      get_all_roles: requestedEndpoints.REACT_APP_SERVICE_GET_ALL_ROLES,
      delete_user: requestedEndpoints.REACT_APP_SERVICE_DELETE_USER,
      update_user: requestedEndpoints.REACT_APP_SERVICE_UPDATE_USER,
      create_user: requestedEndpoints.REACT_APP_SERVICE_CREATE_USER,
      getUserInfo: requestedEndpoints.REACT_APP_SERVICE_GET_USER_INFO,
      forgot_password: requestedEndpoints.REACT_APP_SERVICE_FORGOT_PASSWORD,
      activate_user: requestedEndpoints.REACT_APP_SERVICE_ACTIVATE_USER,
      reset_forgotten_password: requestedEndpoints.REACT_APP_SERVICE_RESET_FORGOTTEN_PASSWORD,
      completeRegistro: requestedEndpoints.REACT_APP_SERVICE_COMPLETE_REGISTRO,
      obs_file: requestedEndpoints.REACT_APP_SERVICE_OBS_FILE,
      linkObsFile: requestedEndpoints.REACT_APP_SERVICE_LINK_OBS_FILE,
      enableTwoFactor: requestedEndpoints.REACT_APP_SERVICE_ENABLE_TWO_FACTOR,
      disableTwoFactor: requestedEndpoints.REACT_APP_SERVICE_DISABLE_TWO_FACTOR,
      sendCode2faenable: requestedEndpoints.REACT_APP_SERVICE_SEND_CODE_2FA_ENABLE,
      sendCode2fadisable: requestedEndpoints.REACT_APP_SERVICE_SEND_CODE_2FA_DISABLE,
      resendCodeTwoFactor: requestedEndpoints.REACT_APP_SERVICE_RESEND_CODE_TWO_FACTOR,
      change_password: requestedEndpoints.REACT_APP_SERVICE_CHANGE_PASSWORD,
      get_management_dashboard: requestedEndpoints.REACT_APP_SERVICE_GET_MANAGEMENT_DASHBOARD,
      get_management_error: requestedEndpoints.REACT_APP_SERVICE_GET_MANAGEMENT_ERROR,
      get_all_privileges: requestedEndpoints.REACT_APP_SERVICE_GET_ALL_PRIVILEGES,
      create_rol: requestedEndpoints.REACT_APP_SERVICE_CREATE_ROL,
      add_rol_to_user: requestedEndpoints.REACT_APP_SERVICE_ADD_ROL_TO_USER,
      get_all_logs: requestedEndpoints.REACT_APP_SERVICE_GET_ALL_LOGS,
      delete_rol_to_user: requestedEndpoints.REACT_APP_SERVICE_DELETE_ROL_TO_USER,
      get_data_charts_node: requestedEndpoints.REACT_APP_SERVICE_GET_DATA_CHARTS_NODE,
      get_statistics_node: requestedEndpoints.REACT_APP_SERVICE_GET_STATISTICS_NODE,
      get_statistics_consorcio: requestedEndpoints.REACT_APP_SERVICE_GET_STATISTICS_CONSORCIO,
      get_charts_data_consorcio: requestedEndpoints.REACT_APP_SERVICE_GET_CHARTS_DATA_CONSORCIO,
      get_chart_pie_consorcio: requestedEndpoints.REACT_APP_SERVICE_GET_CHART_PIE_CONSORCIO,
      get_info_node: requestedEndpoints.REACT_APP_SERVICE_GET_INFO_NODE,
      get_node_metrics: requestedEndpoints.REACT_APP_SERVICE_GET_NODE_METRICS,
      get_allDataTransactions: requestedEndpoints.REACT_APP_SERVICE_GET_ALL_DATA_TRANSACTIONS,
      get_node_info: requestedEndpoints.REACT_APP_SERVICE_GET_NODE_INFO,
      event_logs_catalog: requestedEndpoints.REACT_APP_SERVICE_EVENT_LOGS_CATALOG,
      area_catalogo: requestedEndpoints.REACT_APP_SERVICE_AREA_CATALOGO,
      posicion_catalogo: requestedEndpoints.REACT_APP_SERVICE_POSICION_CATALOGO,
      status_catalog: requestedEndpoints.REACT_APP_SERVICE_STATUS_CATALOG,
      get_info_carga_db: requestedEndpoints.REACT_APP_SERVICE_GET_INFO_CARGA_DB,
      get_nodes_catalog: requestedEndpoints.REACT_APP_SERVICE_GET_NODES_CATALOG,
      get_bitacora_pdf: requestedEndpoints.REACT_APP_SERVICE_GET_BITACORA_PDF,
    };

    // Resolviendo la promesa y liberando el promise guardado
    resolve(endpoints);
    loadEndpointsPromise = null;
  });

  // Retorna la promesa para que todas las llamadas esperen por el mismo resultado
  return loadEndpointsPromise;
};
const api = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
  });
const urlTextPlainEmpty = window.URL.createObjectURL(new Blob([], { type: 'text/plain' }));
api.interceptors.request.use(
  async (config) => {
    if (!endpoints) {
      if (!navigator.onLine) {
        return Promise.reject({
          success: false,
          message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
          name: "NETWORK_CONNECTION"
        });
      }
      await loadEndpoints();
    }
    const { endpointKey, params, extraUrlCustomParams, signal} = config.meta || {};
    const {valuesDelete, extraUrlCustomParamsDelete} = config || {};
    if ((endpointKey && endpoints[endpointKey])|| config?.url) {
      let url = endpoints[endpointKey] || config?.url;
      
      if (params && config.method.toLowerCase() === 'get') {
        const queryString = qs.stringify(params, { addQueryPrefix: true });
        url += queryString;

      }
      if ((params || valuesDelete) && ['post', 'put', 'patch', 'delete'].includes(config.method.toLowerCase())) {
        config.data = params || valuesDelete;
      }
      if (extraUrlCustomParams || extraUrlCustomParamsDelete) {
        const unifiedExtraParams = extraUrlCustomParams?.join('/') || extraUrlCustomParamsDelete?.join('/');
        url = `${url}${unifiedExtraParams}`;
      }
      if (signal) {
        config.signal = signal;
      }

      config.url = url;
      
    } else if (endpointKey) {
      throw new Error(`Endpoint ${endpointKey} no encontrado en la configuración.`);
    }

    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    let singletonVerify = SingletonAPI.getInstance().verifyRequest(config.url, (config.data))
    
      if(singletonVerify !== false){
        let resultado = await singletonVerify;
        //return  Promise.resolve({...resultado, ...config});
        if(resultado.isAxiosError)
            return Promise.reject(resultado);
        else if(resultado?.name === 'AbortError')
          return config;
        else
          return ({
            headers: {},
            method: config.method,
            url: urlTextPlainEmpty,
            meta:{resultadoCache: resultado}
          });
      }
    return config;
  },
  (error) => {
    if (!navigator.onLine) {
      return Promise.reject({
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
        name: "NETWORK_CONNECTION"
      });
    }
    if (error.message === "Network Error") {
      return Promise.reject({
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
        name: "NETWORK_CONNECTION"
      });
    }
    if (error.name === "CanceledError") {
      console.log('Request canceled');
      return {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if(response?.config?.meta?.resultadoCache)
      return response?.config?.meta?.resultadoCache;
    else{
      SingletonAPI.getInstance().saveRequest(response, response.config?.url, (response.config?.data))
      return response;
    }
  }, 
  (error) => {
    let returnedSalida;
    let returnedErrorOthersRequest;
    if (error.message === "NETWORK_CONNECTION") {
      returnedSalida =  Promise.reject({
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
        name: "NETWORK_CONNECTION"
      });
      returnedErrorOthersRequest = (error);
    }
    else if (error.message === "Network Error") {
      returnedSalida =  Promise.reject({
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
        name: "NETWORK_CONNECTION",
      });
      returnedErrorOthersRequest = (error);
    }
    else if (error.name === "CanceledError") {
      console.log('Request canceled');
      returnedSalida =  {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
      returnedErrorOthersRequest = ({
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      });
    }
    
    else if (error.response && error.response.status === 500) {
      if (error.response.message_client.includes("maximo")){
        returnedSalida =  Promise.reject({
          success: false,
          message: error.response.message_client,
        });
        returnedErrorOthersRequest = (error);
      } else {
        returnedSalida =  Promise.reject({
          success: false,
          message: "Error interno del servidor. Por favor, intenta más tarde.",
        });
        returnedErrorOthersRequest = (error);
      }
    }
    else{
      returnedSalida =  Promise.reject(error); 
      returnedErrorOthersRequest = (error);
    }
    SingletonAPI.getInstance().saveRequest(returnedErrorOthersRequest, error.config?.url, (error.config?.data))
    return returnedSalida;
  }
);

const bucket = "dco-bucket-assets-dev-mex2";

dayjs.extend(utc);
dayjs.extend(timezone);

export const checkUserTemporaryLocked = (lockoutend) => {
  const userTime = dayjs().tz(dayjs.tz.guess());
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const providedTimeUTC = dayjs.tz(lockoutend, 'UTC');
  const providedTimeCDMX = providedTimeUTC.tz(userTimeZone);
  const timeDifference = providedTimeCDMX.diff(userTime, 'seconds');
  const minutes = Math.floor(timeDifference / 60);
  const seconds = timeDifference % 60;

  return {
    time: `${minutes}:${seconds}`,
    locked: timeDifference > 0 ? true : false
  }

};

const available_catalogs = {
  "rol": 'get_all_roles',
  "areas": 'area_catalogo',
  "positions": 'posicion_catalogo',
  "status": 'status_catalog',
  "event_logs": 'event_logs_catalog',
  "nodes": 'get_nodes_catalog'
}

export const extractErrorMessages = (messages) => {
  return Object.keys(messages).reduce((acc, key) => {
    const value = messages[key];
    if (Array.isArray(value)) {
        return acc.concat(value);
    } else if (typeof value === 'object' && value !== null) {
        return acc.concat(extractErrorMessages(value));
    }
    return acc;
}, []);
}

const isUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const fileToBase64 = (fileOrBase64) => {
  if (typeof fileOrBase64 === 'string') {
    return Promise.resolve(fileOrBase64);
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const result = reader.result;
      const base64Data = result.split(',')[1];
      resolve(base64Data);
    };

    reader.onerror = reject;

    reader.readAsDataURL(fileOrBase64);
  });
};

export const activateUserService = async (values) => {
  try {
     let res= await api.put('', values, {
      meta: {
        endpointKey: 'activate_user', 
      }
    });
    const { isSucceed } = res?.data;
    if (!isSucceed) {
      return {
        success: false,
        message: "La actualización del estatus del usuario no se a realizado con éxito."
      };
    }
    return {
      success: true,
      message: "La actualización del estatus del usuario se a realizado con éxito.",
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "La actualización del estatus del usuario no se a realizado con éxito.",
    };
  }
};

export const getManagementDashboard = async (init_date, final_date, page, page_size) => {
  try {
    const response = await api.get('', {
      meta: {
        endpointKey: 'get_management_dashboard',
        params: { 
          fecha_inicio: init_date,
          fecha_fin: final_date,
          page: page,
          page_size: page_size
         }
      }
    });
    if (!response) {
      console.error(`Error al obtener datos`);
      return {
        success: false,
        message: `Error al obtener datos`
      }
    }
    return {
      success: true,
      message: "Catálogo obtenidos con éxito",
      data: response
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `Error al obtener catálogo de`
    }
  }
};

export const getManagementError = async (id) => {
  try {
    const response = await api.get('', {
      meta: {
        endpointKey: 'get_management_error',
        extraUrlCustomParams: [ id ]
      }
    });
    if (!response) {
      console.error(`Error al obtener datos`);
      return {
        success: false,
        message: `Error al obtener datos`
      }
    }
    return {
      success: true,
      message: "Catálogo obtenidos con éxito",
      data: response
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `Error al obtener catálogo de`
    }
  }
};

export const getCatalogs = async (catalog) => {
  try {
    const { data } = await api.get('', {
      meta: {
        endpointKey: available_catalogs[catalog],
      }
    });
    if (!data.isSucceed) {
      console.error(`Error al obtener catálogo de ${catalog}`);
      return {
        success: false,
        message: `Error al obtener catálogo de ${catalog}`
      }
    }
    return {
      success: true,
      message: "Catálogo obtenidos con éxito",
      data: data.data
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `Error al obtener catálogo de ${catalog}`
    }
  }
};

export const getNodeCatalog = async (catalog) => {
  try {
    const { data } = await api.get('', {
      meta: {
        endpointKey: available_catalogs[catalog],
      }
    });
    if (!data.payload) {
      console.error(`Error al obtener catálogo de ${catalog}`);
      return {
        success: false,
        message: `Error al obtener catálogo de ${catalog}`
      }
    }
    return {
      success: true,
      message: "Catálogo obtenidos con éxito",
      data: data.payload?.items ?? []
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : `Error al obtener catálogo de ${catalog}`
    }
  }
};

export const resetPassword = async (values) => {
  try {
    const { data } = await api.post('', values, {
      meta: {
        endpointKey: 'reset_forgotten_password', 
      }
    });
    if (!data.isSucceed) {
        return {
          success: false,
          message: extractErrorMessages(data?.messages ?? {})
        }
    }
    return {
      success: true,
      message: "Contraseña actualizada con éxito"
    };
  } catch (error) {
    console.error(error);
    if(error?.response?.data?.messages){
      let keys = Object.keys(error?.response?.data?.messages);
      if(keys.length>0)
        return {
          success: false,
          message: error?.response?.data?.messages[keys[0]],
          email: false
        }
        
      else
        return {
          success: false,
          message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error, intente más tarde",
          email: false
        }
    }
    else
      return {
        success: false,
        message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error, intente más tarde"
      }
  }
}

export const forgotPassword = async (email) => {
  try {
    const { data } = await api.post('', { email }, {
      meta: {
        endpointKey: 'forgot_password', 
      }
    });
    
    if (!data?.isSucceed) {
      return {
        success: false,
        message: data?.messages.hasOwnProperty("email") ? "El email proporcionado no existe" : "Lo sentimos ocurrió un error, intente más tarde",
        email: data?.messages.hasOwnProperty("email") ? true : false
      }
    }
    return {
      success: true,
      message: "Recuperación exitosa"
    };
  } catch (error) {
    console.error(error);
    if(error?.response?.data?.messages){
      let keys = Object.keys(error?.response?.data?.messages);
      if(keys.length>0)
        return {
          success: false,
          message: error?.response?.data?.messages[keys[0]],
          email: false
        }
        
      else
        return {
          success: false,
          message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error, intente más tarde",
          email: false
        }
    }
    else
      return {
        success: false,
        message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error, intente más tarde",
        email: false
      }
  }
}
export const getAllRolesService = async () => {
  try {
    const { data } = await api.get('', {
      meta: {
        endpointKey: 'get_all_roles',
      }
    });
    if (!data?.isSucceed) {
      console.error("Error al obtener catálogo de roles");
      return {
        success: false,
        message: "Error al obtener catálogo de roles"
      }
    }
    return {
      success: true,
      message: "Catálogos obtenidos con éxito",
      data: data.data
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : "Error al obtener catálogo de roles"
    }
  }
};

export const getAllPrivilegesService = async () => {
  try {
    const { data } = await api.get('', {
      meta: {
        endpointKey: 'get_all_privileges',
      }
    });
    const { isSucceed, data: responseData } = data;
    if (!isSucceed) {
      console.error("Error al obtener catálogo de privilegios");
      return {
        success: false,
        message: "Error al obtener catálogo de privilegios"
      }
    }
    return {
      success: true,
      message: "Catálogos obtenidos con éxito",
      data: responseData
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : "Error al obtener catálogo de roles"
    }
  }
};

export const createRolService = async (values) => {
  try {
    const response = await api.post('', values, {
      meta: {
        endpointKey: 'create_rol', 
      }
    });
    if (!response?.data?.isSucceed) {
      return {
        success: false,
        message: response?.data?.messages?.validation?
                    response?.data?.messages?.validation[0]
                    : 
                    response?.data?.messages||"El nuevo rol no ha sido creado"
      };
    }
    return {
      success: true,
      message: "El nuevo rol ha sido creado con éxito",
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : "El nuevo rol no ha sido creado",
    };
  }
};

export const deleteUserById = async (id) => {
  try {
    const response = await api.delete(endpoints.delete_user, {extraUrlCustomParamsDelete: [id]}, {
      meta: {
        endpointKey: 'delete_user', 
        extraUrlCustomParams: [id]
      }
    });
    const { isSucceed } = response.data;
    if (!isSucceed) {
      console.error("Error al eliminar usuario");
      return {
        success: false,
        message: "Error al eliminar usuario"
      }
    }
    return {
      success: true,
      message: "Usuario eliminado con éxito",
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Error al eliminar usuario"
    }
  }
};

export const getAllUsersService = async ({
  current_page,
  search,
  status,
  register_date,
  rol,
  limit,
  signal,
  ...props
}) => {
  try {
    const params = {
      ...(limit ? { PageSize: endpoints?.pagination_size ?? '10' } : { PageSize: -1, PageNumber: -1}),
      ...(limit && current_page ? { PageNumber: current_page } : { PageSize: -1, PageNumber: -1}),
      ...(register_date ? { FechaAlta: dayjs(register_date).format('YYYY-MM-DD').toString() } : {}),
      ...(rol ? { Rol: rol } : {}),
      ...(search ? { Match: search } : {}),
      ...(status ? { Status: status } : {}),
      ...(props.OrderAscending ? { OrderAscending: props.OrderAscending === 'asc' ? 'true' : 'false' } : {}),
      ...(props.OrderBy ? { OrderBy: props.OrderBy } : {}),
    }
    const response = await api.get('', {
      meta: {
        endpointKey: 'get_all_users',
        params,
        signal,
      }
    });
    const { isSucceed, data } = response?.data || response;
    if (!isSucceed) {
      console.error("Error al obtener los usuarios");
      if(response?.data?.messages?.Limit){
        return {
          success: false,
          message:response?.data?.messages?.Limit[0]||"Error al obtener los usuarios",
          name: response?.name||response?.data?.name||"Error de limite"
        }
      }
      else
        return {
          success: false,
          message: response?.message||"Error al obtener los usuarios",
          name: response?.name||response?.data?.name||"Error al obtener los usuarios"
        }
    }
    return {
      success: true,
      message: "Datos de los usuarios obtenidos con éxito",
      data: data,
      pagination: JSON.parse(response.headers.get('x-pagination'))
    }
  } catch (error) {
    if (error.success === false && error.message) {
      return error;
    }
    if (error.name === "CanceledError") {
      console.log('Request canceled');
      return {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
    }
    console.error(error);
    return {
      success: false,
      message: "Error al obtener los usuarios"
    }
  }
};

export const exportUsersService = async ({
  search,
  status,
  register_date,
  rol,
  OrderAscending,
  OrderBy
}) => {
  try {
    const params = {
      ...(register_date ? { FechaAlta: dayjs(register_date).format('YYYY-MM-DD').toString() } : {}),
      ...(rol ? { Rol: rol } : {}),
      ...(search ? { Match: search } : {}),
      ...(status ? { Status: status } : {}),
      ...(OrderAscending ? { OrderAscending: OrderAscending === 'asc' ? 'true' : 'false' } : {}),
      ...(OrderBy ? { OrderBy: OrderBy } : {}),
    }
    const { data } = await api.get('', {
      meta: {
        endpointKey: 'export_all_users',
        params,
      }
    });
    if(!data.isSucceed){
      if(data?.messages?.Limit){
        return {
          success: false,
          message:data?.messages?.Limit[0]||"Error al obtener los usuarios",
        }
      }
      else{
        return {
          success: false,
          message: "Error al exportar archivo"
        }
      }
    }
    

    const blobFile = base64ToBlob(data.data, 'application/pdf');
    saveAs(blobFile, "Usuarios.pdf");
    return {
      success: true,
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Error al exportar archivo"
    }
  }
};

export const validateEmailLogin = async (email) => {
  try {
    const { data } = await api.get('', {
      meta: {
        endpointKey: 'valid_email',
        params: { email }
      }
    });
    const { isSucceed, data: response_data, ...back_response } = data;
    if (!isSucceed) {
      return {
        success: false,
        error_message: back_response?.messages?.hasOwnProperty("email")
          ? "El correo ingresado no es válido, favor de verificarlo"
          : "Lo sentimos ocurrió un error",
      };
    }
    if (response_data?.status === 0) {
      return {
        success: false,
        error_message: "El usuario se encuentra bloqueado. Ponte en contacto con el administrador de tu cuenta o ve a ayuda para resolver tu caso",
      }
    }
    if (response_data?.status === 2) {
      return {
        success: false,
        error_message: "El usuario que estás utilizando, se encuentra inactivo. Ponte en contacto con el administrador de tu cuenta o ve a ayuda para resolver tu caso",
      }
    }
    if (response_data?.lockoutEnd) {
      const { time, locked } = checkUserTemporaryLocked(response_data?.lockoutEnd);
      if (locked) {
        return {
          success: false,
          error_message: `El usuario que estás utilizando, se encuentra bloqueado temporalmente. Por favor espera ${time} minutos para volver a intentarlo.`,
        }
      }
    }
    return {
      success: true,
      data: response_data,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error_message: "Lo sentimos ocurrió un error",
    };
  }
};

export const emailExist = async (email) => {
  try {
    const { data } = await api.get('', {
      meta: {
        endpointKey: 'valid_email',
        params: { email }
      }
    });
    return data?.isSucceed;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const available_details = {
  "Failed": (count, two_fa) => ({
    success: false,
    error_message: two_fa ? `El código ingresado es incorrecto o ha expirado. Intentos restantes ${(parseInt(count.replaceAll("AccessFailedCount: ", ""))>3?7:4) - parseInt(count.replaceAll("AccessFailedCount: ", ""))}` : `La contraseña ingresada es incorrecta. Intentos restantes ${(parseInt(count.replaceAll("AccessFailedCount: ", ""))>3?7:4) - parseInt(count.replaceAll("AccessFailedCount: ", ""))}`,
    locked: false,
  }),
  "User locked": (count, two_fa) => ({
    success: false,
    locked: true,
    error_message: "Has excedido el número de intentos máximos de ingreso a tu cuenta. Por seguridad, se ha realizado un bloqueo a tu cuenta. Ponte en contacto con el administrador de tu cuenta para resolver tu caso",
    contactar_admin : true
  }),
  "LockedOut": (count, two_fa) => ({
    success: false,
    locked: true,
    error_message: two_fa ? "El código ingresado es incorrecto. Se ha realizado un bloqueo temporal de 15 minutos" : "La contraseña ingresada es incorrecta. Se ha realizado un bloqueo temporal de 15 minutos",
  }),
  "RequiresTwoFactor": () => ({
    success: true,
    two_factor: true,
  }),
  "User inactive": () => ({
    success: false,
    error_message: "Usuario inactivo",
  }),
  "User block timestimestamp": (count, two_fa) => ({
    success: false,
    locked: true,
    error_message: two_fa ? "El código ingresado es incorrecto. Se ha realizado un bloqueo temporal de 15 minutos" : "La contraseña ingresada es incorrecta. Se ha realizado un bloqueo temporal de 15 minutos",
    contactar_admin : true

  })

}

export const login_status = {
  200: ( data ) => ({
    success: true,
    data,
    two_factor: false,
  }),
  400: (data) => {
    if (data?.messages?.user){
      const [details, count] = data.messages.user;
      return available_details[details](count, data.two_fa);
    }
  },
}

export const login = async (values) => {
  try {
    const { status, data } = await api.post('', values, {
      meta: {
        endpointKey: 'login',
      }
    });
    return login_status[status](data);
  } catch (error) {
    console.error(error);
    const { status, data } = error.response;
    return login_status[status]({...data, two_fa: values?.twoFactorCode ? true : false});
  }
};

export const login_two_factor = async (values) => {
  try {
    const { isSucceed, data, ...back_response } = await api.post('', values, {
      meta: {
        endpointKey: 'login_two_factor', 
      }
    });
    
    if (!isSucceed) {
      return {
        success: false,
        message: back_response.messages?.hasOwnProperty("token")
          ? "Código de verificación incorrecto"
          : "Lo sentimos ocurrió un error, intente más tarde",
      };
    }
    return {
      success: true,
      message: "Login exitoso",
      data: data,
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, intente más tarde",
    };
  }
};
export const refreshToken = async () => {
  try {
    const payload = {
      refreshToken: sessionStorage.getItem("refreshToken"),
    };
    const { data } = await api.post('', payload, {
      meta: {
        endpointKey: 'refresh_token', 
      }
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    console.error(error);
    return false;
  }
};
export const peticionExample = () => {
  return fetch(endpoints.example);
}
export const getCatalogoAreas = () => {
  return api.get('', {
      meta: {
        endpointKey: 'area_catalogo',
      }
    })
}
export const getCatalogoPosicion = () => {
  return api.get('', {
      meta: {
        endpointKey: 'posicion_catalogo',
      }
    })
}

export const getInfoUser = (idOrEmail) => {
  try {
    return api.get('', {
      meta: {
        endpointKey: 'getUserInfo',
        extraUrlCustomParams: [ idOrEmail ]
      }
    });
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Error al obtener datos del usuario"
    }
  }
}

export const getByIDService = async (id) => {
  try {
    const response = await api.get('', {
      meta: {
        endpointKey: 'getUserInfo',
        extraUrlCustomParams: [ id ]
      }
    });
    const { isSucceed, data } = response.data;
    if (!isSucceed) {
      console.error("Error al obtener datos del usuario");
      return {
        success: false,
        message: "Error al obtener datos del usuario"
      }
    }
    return {
      success: true,
      message: "Datos del usuario obtenidos con éxito",
      data: {
        ...data.user,
        area: data.user.areaId,
        puesto: data.user.puestoId
      },
      rol: data.roles[0].name
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Error al obtener datos del usuario"
    }
  }
};
export const completeRegistro = async (userID, emailToken, passworToken, newPassword, imagenPerfilURL, areaID, puestoID, fecha) => {
  try {
    const payload = {
      userId: userID,
      emailConfirmationToken: emailToken,
      passwordResetToken: passworToken,
      newPassword: newPassword,
      imagenPerfil: imagenPerfilURL,
      areaId: areaID,
      puestoID: puestoID,
      birthDate: fecha
    };
    const { data } = await api.patch('', payload, {
      meta: {
        endpointKey: 'completeRegistro', 
      }
    });
    if (!data.isSucceed) {
      console.error("Problemas al terminar su registro'");
      return {
        success: false,
        message: data?.messages ? extractErrorMessages(data?.messages) : "Problemas al terminar su registro'"
      }
    }
    return {
      success: true,
      message: 'Registro Completado Correctamente'
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : "Problemas al terminar su registro'"
    }
  }
}

export const subirArchivoOBSBlobFolder = async (blobImg, apikey, folder, nombreFile) => {
  try {
    let controller = new AbortController();
    let header = new Headers();
    header.append("apikey", apikey);
    header.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    let data = new FormData();
    data.append("file", blobImg, nombreFile);
    data.append("folder", folder);
    data.append("bucket", bucket);
    let response = await fetch(endpoints.obs_file, {
      method: 'post',
      headers: header,
      body: data,
      signal: controller.signal
    });
    let link = await obtenerURLOBSFolder(apikey, folder, nombreFile);
    let salida = { OK: response.ok, status: response.status, data: response, url: (await link.json()).payload.link };
    return salida;
  } catch (error) {
    let salida = { OK: false, status: "Error Network", error: error };
    return salida;
  }

}
const obtenerURLOBSFolder = async (apikey, folder, nombreFile) => {
  let header = new Headers();
  header.append("apikey", apikey);
  header.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
  return fetch(endpoints.linkObsFile + `&folder=${folder}&namefile=${nombreFile}`, {
    method: 'get',
    headers: header,
  });
}

export const actualizarPasswordUser = (email, oldPass, newPass, codigo) => {
  const payload = {
    code: codigo,
    oldPassword: oldPass,
    newPassword: newPass
  };

  return api.post('', payload, {
    meta: {
      endpointKey: 'change_password', 
    }
  });
}
export const activarTwoFactor = (email, activar = true) => {
  return api.get('', {
    meta: {
      endpointKey: activar ? 'enableTwoFactor' : 'disableTwoFactor',
      params: { email }
    }
  });
}

export const enviarCodigoTwoFactor = (codigo, email, activar = true) => {
  const payload = {
    "email": email,
    "code": codigo
  };

  return api.post('', payload, {
    meta: {
      endpointKey: activar ? 'sendCode2faenable' : 'sendCode2fadisable', 
    }
  });
}
export const resendCodeTwoFactor = async (email) => {
  try {
    const data = await api.get('', {
      meta: {
        endpointKey: 'resendCodeTwoFactor',
        params: { email }
      }
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    console.error(error);
    return false;
  }
};
export const addRolUserService = async (values) => {
  try {
    let res= await api.post('', values, {
      meta: {
        endpointKey: 'add_rol_to_user', 
      }
    });
    const {isSucceed} = res.data;
    if (!isSucceed) {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error, al agregar roles",
      };
    }
    return {
      success: true,
      message: "Usuario actualizado con éxito",
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al agregar roles",
    };
  }
};

export const deleteRolUserService = async (values) => {
  try {
    let response =  await api.delete(endpoints.delete_rol_to_user, {valuesDelete: values}, {
      meta: {
        endpointKey: 'delete_rol_to_user', 
      }
    });
    const {isSucceed} = response?.data;
    if (!isSucceed) {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error, al eliminar roles",
      };
    }
    return {
      success: true,
      message: "Usuario actualizado con éxito",
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al eliminar roles",
    };
  }
};

export const changeUserStatusService = async ({ id, status }) => {
  try {
    const payload = {
      estatus: parseInt(status),
    };
    const { data } = await api.put('', payload, {
      meta: {
        endpointKey: 'update_user', 
        extraUrlCustomParams: [ id ]
      }
    });
    const { isSucceed } = data;
    if (!isSucceed) {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error, intente más tarde",
      };
    }
    return {
      success: true,
      message: "La actualización del estatus del usuario se ha realizado con éxito.",
    };
  } catch (error) {
    console.error(error);
    if(error?.response?.data?.messages){
      let keys = Object.keys(error?.response?.data?.messages);
      if(keys.length > 0){
        return {
          success: false,
          message: error?.response?.data?.messages[keys[0]],
        };
      }
      else{
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde",
        };
      }
    }
    else
      return {
        success: false,
        message: "Lo sentimos ocurrió un error, intente más tarde",
      };
  }
};

export const editUserService = async (values) => {
  try {
    const payload = {
      ...(values.name ? { nombre: values.name } : {}),
      ...(values.paternal_surname ? { apellidoPaterno: values.paternal_surname } : {}),
      ...(values.maternal_surname ? { apellidoMaterno: values.maternal_surname } : {}),
      ...(values.birdth_date ? { fechaNacimiento: values.birdth_date } : {}),
      ...(values.profile_image ? { imagenPerfil: await fileToBase64(values.profile_image) } : {}),
      ...(values.phone ? { telefono: values.phone } : {}),
      ...(values.permissions ? { rol: values.permissions } : {}),
      ...(values.area ? { areaId: parseInt(values.area) } : {}),
      ...(values.position ? { puestoId: parseInt(values.position) } : {}),
      ...(values.roleIds ? { roleIds: values.roleIds } : {}),
      ...(values.estatus ? { estatus: values.estatus } : {}),
    }
    const { data } = await api.put('', payload, {
      meta: {
        endpointKey: 'update_user', 
        extraUrlCustomParams: [ values.id ] 
      }
    });
    
    if (!data?.isSucceed) {
      if(data?.messages?.validations?.length > 0)
        return {
          success: false,
          message: data?.messages?.validations[0],
        };
      else if(data?.messages){
        let keys = Object.keys(data?.messages)
        if (keys.length > 0)
          return {
            success: false,
            message: data?.messages[keys[0]],
          };
        else
          return {
            success: false,
            message: "Lo sentimos ocurrió un error, intente más tarde",
          };
      }
      else
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde",
        };
    }
    return {
      success: true,
      message: "Usuario actualizado con éxito",
    };
  } catch (error) {
    console.error(error);
    if(error?.response?.data?.messages){
      let keys = Object.keys(error?.response?.data?.messages);
      if (keys.length > 0) {
        return {
          success: false,
          message: error?.response?.data?.messages[keys[0]]
        };
      }
      else
        return {
          success: false,
          message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error, intente más tarde",
        };
    }
    else
      return {
        success: false,
        message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error, intente más tarde",
      };
  }
};

const existingErrors = {
  "DuplicateUserName": "El nombre de usuario ya está en uso.",
  "DuplicateEmail": "El correo electrónico ya está registrado.",
  "InvalidUserName": "El nombre de usuario contiene caracteres no permitidos.",
  "InvalidEmail": "El correo electrónico no tiene un formato válido.",
  "PasswordTooShort": "La contraseña no cumple con la longitud mínima.",
  "PasswordRequiresNonAlphanumeric": "La contraseña debe contener al menos un carácter no alfanumérico.",
  "PasswordRequiresDigit": "La contraseña debe contener al menos un dígito.",
  "PasswordRequiresLower": "La contraseña debe tener al menos una letra minúscula.",
  "PasswordRequiresUpper": "La contraseña debe tener al menos una letra mayúscula.",
  "PasswordMismatch": "La contraseña proporcionada no coincide con la actual.",
}

const getAllErrors = (response) => {
  try {
    if (Object.keys(response.messages)[0]) {
      console.log(Object.keys(response.messages)[0])
      if (Object.keys(response.messages)[0] === "validations")
        return response.messages.validations.join("\n")
    }
    const error = existingErrors[Object.keys(response.messages)[0]];
    return error;
  } catch (error) {
    return "Lo sentimos ocurrió un error"
  }
};

export const createUserService = async ({
  name,
  paternal_surname,
  maternal_surname,
  phone,
  email,
  roleIds,
}) => {
  try {
    const payload = {
      nombre: name,
      apellidoPaterno: paternal_surname,
      apellidoMaterno: maternal_surname,
      email,
      telefono: phone,
      extension: "52",
      roleIds,
    }
    const { data } = await api.post('', payload, {
      meta: {
        endpointKey: 'create_user', 
      }
    });
    if (!data.isSucceed) {
      const errors = getAllErrors(data);
      return {
        success: false,
        message: errors ?? "Lo sentimos ocurrió un error, intente más tarde",
      };
    }
    return {
      success: true,
      message: "Usuario creado con éxito",
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error, intente más tarde",
    };
  }
};

//Charts - Dashboard
export const getStatisticsNodeByDate = async (uuidNode, startDate, endDate) => {
  let data = { success: false, data: {}, message: '' }

  if (!navigator.onLine) {
    throw new Error("NETWORK_CONNECTION");
  }

  try {
    const params = JSON.stringify({
      UUID: uuidNode,
      startDate: startDate,
      endDate: endDate
    });
    const { data: result } = await api.post('', params, {
      meta: {
        endpointKey: 'get_statistics_node'
      }
    });
    result.Respuesta ?
      data = { success: false, data: result, message: 'Consulta éxitosa - Datos incorrectos' }
      :
      data = { success: true, data: result, message: 'Consulta éxitosa' }
  } catch (error) {
    console.warn(`✨ ~ file: api.js:779 ~ getStatisticsNodeByDate ~ error:`, error)
    if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch')
      data = {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      }
    else
      data = { success: false, data: {}, message: 'Error desconocido, por favor intenta nuevamente.' }
  } finally {
    return data;
  }
}

export const getDataChartsNodeByDate = async (uuidNode, startDate, endDate) => {
  let data = { success: false, data: {}, message: '' }

  if (!navigator.onLine) {
    throw new Error("NETWORK_CONNECTION");
  }

  try {
    const params = JSON.stringify({
      UUID: uuidNode,
      startDate: startDate,
      endDate: endDate
    });
    const { data: result } = await api.post('', params, {
      meta: {
        endpointKey: 'get_data_charts_node'
      }
    });
    result.Respuesta ?
      data = { success: false, data: result, message: 'Consulta éxitosa - Datos incorrectos' }
      :
      data = { success: true, data: result, message: 'Consulta éxitosa' }
  } catch (error) {
    console.warn(`✨ ~ file: api.js:809 ~ getDataChartsNodeByDate ~ error:`, error)
    if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch')
      data = {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      }
    else
      data = { success: false, data: {}, message: 'Error desconocido, por favor intenta nuevamente.' }
  } finally {
    return data;
  }
}

export const getStatisticsConsorcioAll = async (startDate, endDate) => {
  let data = { success: false, data: {}, message: '' }

  if (!navigator.onLine) {
    throw new Error("NETWORK_CONNECTION");
  }

  try {
    const params = JSON.stringify({
      startDate: startDate,
      endDate: endDate
    });
    const { data: result } = await api.post('', params, {
      meta: {
        endpointKey: 'get_statistics_consorcio'
      }
    });
    if (result) {
      result.Respuesta ?
        data = { success: false, data: result, message: 'Consulta éxitosa - Datos incorrectos' }
        :
        data = { success: true, data: result, message: 'Consulta éxitosa' }
    } else {
      data = { success: false, data: [], message: 'Consulta éxitosa - Sin registros' }
    }
  } catch (error) {
    console.warn(`✨ ~ file: api.js:855 ~ getStatisticsConsorcioAll ~ error:`, error)
    if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch')
      data = {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      }
    else
      data = { success: false, data: {}, message: 'Error desconocido, por favor intenta nuevamente.' }
  } finally {
    return data;
  }
}

export const getMetricsAll = async (startDate, endDate) => {
  let data = { success: false, data: {}, message: '' }

  if (!navigator.onLine) {
    throw new Error("NETWORK_CONNECTION");
  }

  try {
    const params = JSON.stringify({
      startDate: startDate,
      endDate: endDate
    });
    const { data: result } = await api.post('', params, {
      meta: {
        endpointKey: 'get_charts_data_consorcio'
      }
    });
    result.Respuesta ?
      data = { success: false, data: result, message: 'Consulta éxitosa - Datos incorrectos' }
      :
      data = { success: true, data: result, message: 'Consulta éxitosa' }
  } catch (error) {
    console.error(`✨ ~ file: api.js:1128 ~ getMetricsAll ~ error:`, error.message)
    if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch') {
      console.log("🚀 ~ getMetricsAll ~ error.message:", error.message)
      data = {
        data: {},
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    } else
      data = { success: false, data: {}, message: 'Error desconocido, por favor intenta nuevamente.' }
  } finally {
    return data;
  }
}

export const getTransactionsAllNodes = async (startDate, endDate) => {
  let data = { success: false, data: {}, message: '' }

  if (!navigator.onLine) {
    throw new Error("NETWORK_CONNECTION");
  }

  try {
    const params = JSON.stringify({
      startDate: startDate,
      endDate: endDate
    });
    const { data: result } = await api.post('', params, {
      meta: {
        endpointKey: 'get_chart_pie_consorcio'
      }
    });
    result.Respuesta ?
      data = { success: false, data: result, message: 'Consulta éxitosa - Datos incorrectos' }
      :
      data = { success: true, data: result, message: 'Consulta éxitosa' }
  } catch (error) {
    console.error(`✨ ~ file: api.js:1128 ~ getMetricsAll ~ error:`, error.message)
    if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch')
      data = {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      }
    else
      data = { success: false, data: {}, message: 'Error desconocido, por favor intenta nuevamente.' }
  } finally {
    return data;
  }
}

//Transactions 
export const getDataTransactions = async ({
  page,
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  superscore,
  response_time,
  all_nodes,
  all,
  signal,
  ...props
}) => {
  try {
    const params = {
      page: page ?? 1,
      perPage: endpoints?.pagination_size ?? '10',
      ...(response_time.length === 2 ? { maxmintiemporespuestafield: 'tiempo_respuesta', mintiemporespuestavalue: response_time[0], maxtiemporespuestavalue: response_time[1]} : {}),
      ...(search ? { search: search } : {}),
      ...(start_date !== '' && end_date !== '' ? { startDate: toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString(), endDate: toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString() } : {}),
      ...(props.OrderAscending ? { order: props.OrderAscending === 'asc' ? 'desc' : 'asc' } : {}),
      ...(props.OrderBy ? { sort: props.OrderBy } : {}),
      ...(all_nodes ? { searchfield: 'idNodo', searchfieldtext: all_nodes } : {}),
      ...(all ? { searchfieldvalidacion: 'validacion', searchfieldvalidaciontext: all } : {}),
    };
    const { data } = await api.get('', {
      meta: {
        endpointKey: 'get_allDataTransactions',
        params,
        signal
      }
    });
    return {
      data: data,
      success: true,
      message: "Datos obtenidos con éxito",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'AbortError') {
      console.log('Request canceled');
      return {
        success: false,
        message: "Lo sentimos ocurrió un error al obtener las transacciones",
        name: 'AbortError'
      };
    } else if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error",
      ...(error.name === 'AbortError' ? {name: error.name} : {})
    };
  }
}

export const downloadTransactions = async ({
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  all_nodes,
  all,
  response_time,
  download,
  ...props
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const response = await fetch(`${endpoints.get_allDataTransactions}?${response_time.length === 2 ? `&maxmintiemporespuestafield=tiempo_respuesta&mintiemporespuestavalue=${response_time[0]}&maxtiemporespuestavalue=${response_time[1]}` : ''}${search !== '' ? `&search=${translateTransactionsSerach(search)}` : ''}${start_date !== '' && end_date !== '' ? `${start_date !== '' || start_hours !== '' ? `&startDate=${toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString()}` : ''}${end_date !== '' || end_hours !== '' ? `&endDate=${toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()}` : ''}` : ''}${all_nodes ? `&searchfield=idNodo&searchfieldtext=${all_nodes}` : ''}${all ? `&searchfieldvalidacion=validacion&searchfieldvalidaciontext=${all}` : ''}${download.params}${props.OrderAscending ? `&order=${props.OrderAscending === 'asc' ? 'desc' : 'asc'}` :''}${props.OrderBy ? `&sort=${props.OrderBy}`: ''}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (!response.ok) {
      let error = await response.json();
      if(error && error?.length > 0){
        return {
          success: false,
          message: error[0].message_client || "Error al generar documento.",
        };
      }
      return {
        success: false,
        message: "Error al generar documento.",
      };
    }
    const data = await response.blob()
    saveAs(data, download.name);
    return {
      success: true
    };
  } catch (error) {
    if (error.message === "NETWORK_CONNECTION") {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    console.error(error);
    return {
      success: false,
      message: "Error al generar documento.",
    };;
  }
}
// Node
export const getNodeInfo = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const response = await fetch(`${endpoints.get_info_node}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const parsedResponse = await response.json();
    return {
      data: parsedResponse ?? {},
      success: true,
      message: "Datos obtenidos con éxito",
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al agregar roles",
    };
  }
}

export const getTransactions = async () => {
  try {
    const response = await fetch(endpoints.get_transactions);
    if (!response.ok) {
      return {
        success: false,
        email: false,
      };
    }
    const response_2 = await response.json();
    if (!response_2) {
      console.error(`Error al obtener datos`);
      return {
        success: false,
        message: `Error al obtener datos`
      }
    }
    return {
      success: true,
      message: "Catálogo obtenido con éxito",
      data: response_2
    }
  }
  catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al agregar roles",
    };
  }
}

export const getNodeMetrics = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const response = await fetch(`${endpoints.get_node_metrics}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const parsedResponse = await response.json();
    return {
      data: parsedResponse ?? {},
      success: true,
      message: "Datos obtenidos con éxito",
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al agregar roles",
    };
  }
}

// Bitacora

export const getAllLogsService = async ({
  current_page,
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  limit,
  signal,
  total_registers,
  type_event,
  ...props
}) => {
  try {
    const params = {
      ...(limit ? {itemsPerPage: endpoints?.pagination_size ?? '10', ...(current_page ? {PageNumber: current_page} : {})} : {itemsPerPage: -1, PageNumber: -1}),
      ...(props.OrderAscending ? {ascending: props.OrderAscending === 'asc' ? 'false' : 'true'} : {}),
      ...(props.OrderBy ? {orderBy: props.OrderBy} : {}),
      ...(start_date !== '' && end_date !== '' ? {fechaInicio: toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString(), fechaFin: toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()} : {}),
      ...(type_event !== ''? {logLevel: type_event} : {}),
      ...(search ? {searchText: search} : {})
    }
    const { data, headers } = await api.get('', {
      meta: {
        endpointKey: 'get_all_logs',
        params,
        signal
      }
    });
    if( signal.aborted){
      return {
        success: false,
        message: "Lo sentimos ocurrió un error al obtener las transacciones",
        name: 'AbortError'
      };
    }
    if (!data?.isSucceed) {
      return {
        success: false,
        message: data?.messages[Object.keys(data.messages)[0]] ?? "Lo sentimos ocurrió un error"
      }
    }
    return {
      success: true,
      message: "Datos de los usuarios obtenidos con éxito",
      data: data?.data,
      pagination: JSON.parse(headers.get('x-pagination'))
    }
  } catch (error) {
    console.error(error);
    if (error.message === "NETWORK_CONNECTION") {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    if (error.name === "CanceledError") {
      console.log('Request canceled');
      return {
        success: false,
        message: "Lo sentimos ocurrió un error al obtener las transacciones",
        name: 'AbortError'
      };
    } else if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    return {
      success: false,
      message: "Error al obtener los usuarios"
    }
  }
};

export const exportBitacoraService = async ({
  current_page,
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  limit,
  signal,
  total_registers,
  type_event,
  ...props
}) => {
  try {
    const params = {
      ...(props.OrderAscending ? ({ ascending: ((props.OrderAscending === 'asc') ? 'false' : 'true')}) : {}),
      ...(props.OrderBy ? {orderBy: props.OrderBy} : {}),
      ...(start_date !== '' && end_date !== '' ? {fechaInicio: toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString(), fechaFin: toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()} : {}),
      ...(type_event !== ''? {logLevel: type_event.toString()} : {}),
      ...(search ? {searchText: search} : {}),
      itemsPerPage: "-1", 
      PageNumber: "-1"
    }
    const { data } = await api.get('', {
      meta: {
        endpointKey: 'get_bitacora_pdf',
        signal,
        params
      }
    });
    if(signal.aborted){
      return {
        success: false,
        message: "Lo sentimos ocurrió un error al obtener las transacciones",
        name: 'AbortError'
      };
    }
    if (!data?.isSucceed) {
      return {
        success: false,
        message: data?.messages[Object.keys(data.messages)[0]] ?? "Lo sentimos ocurrió un error"
      }
    }

    const blobFile = base64ToBlob(data?.data, 'application/pdf');
    saveAs(blobFile, "Bitacora.pdf");

    return { success: true, data: data?.data };
  } catch (error) {
    console.error("Error en exportBitacoraService:", error.message || error);
    return { success: false, message: error.message || "Error al obtener los usuarios" };
  }
};

export const blockUsuario = async (values) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const reqBody = JSON.stringify({
      ...(values.estatus.toString() === "2" ? { estatus: parseInt(values.estatus) } : {}),
    })

    const response = await fetch(`${endpoints.update_user}/${values.id}`, {
      method: "PUT",
      headers: myHeaders,
      body: reqBody
    });

    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { isSucceed } = await response.json();

    if (!isSucceed) {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error, intente más tarde",
      };
    }
    return {
      success: true,
      message: "Usuario actualizado con éxito",
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, intente más tarde",
    };
  }
};

// Cargaas DB

export const getAllLogsServiceDB = async ({
  page,
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  superscore,
  response_time,
  signal,
  ...props
}) => {
  try {
    const params = {
      page: page,
      perPage: endpoints?.pagination_size ?? '10',
      ...(response_time.length === 2 ? { maxmintiemporespuestafield: 'tiempo_respuesta', mintiemporespuestavalue: response_time[0], maxtiemporespuestavalue: response_time[1]} : {}),
      ...(search ? {search: search } : {}),
      ...(start_date !== '' && end_date !== '' ? {startDate: toUtcTimeZone(`${start_date} ${start_hours !== '' ? (start_hours) : '00:00'}`).toISOString(), endDate: toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()} : {}),
      ...(props.OrderAscending ? {order: props.OrderAscending === 'asc' ? 'desc' : 'asc'} : {}),
      ...(props.OrderBy ? {sort: props.OrderBy} : {})
    }
    const { data } = await api.get('', {
      meta: {
        endpointKey: 'get_info_carga_db',
        params,
        signal
      }
    });
    return {
      data: data?.payload?.items ?? [],
      success: true,
      message: "Datos obtenidos con éxito",
    };
  } catch (error) {
    console.error(error);
    if (error.name === "CanceledError") {
      console.log('Request canceled');
      return {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
    }
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error",
    };
  }
}

export const downloadInfoCargaDB = async ({
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  superscore,
  response_time,
  download,
  signal,
  OrderAscending,
  OrderBy
}) => {
  try {
    console.log("downloadInfoCargaDB");
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let order = OrderAscending ==='asc'?'desc' : 'asc';
    const response = await fetch(`${endpoints.get_info_carga_db}?${response_time.length === 2 ? `&maxmintiemporespuestafield=tiempo_respuesta&mintiemporespuestavalue=${response_time[0]}&maxtiemporespuestavalue=${response_time[1]}` : ''}${search !== '' ? `&search=${translateTransactionsSerach(search)}` : ''}${start_date !== '' && end_date !== '' ? `${start_date !== '' || start_hours !== '' ? `&startDate=${toUtcTimeZone(`${start_date} ${start_hours !== '' ? (start_hours) : '00:00'}`).toISOString()}` : ''}${end_date !== '' || end_hours !== '' ? `&endDate=${toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()}` : ''}` : ''}${download.params}${OrderAscending?'&order='+order:''}${OrderBy?('&sort='+OrderBy): ''}`, {
      method: "GET",
      headers: myHeaders,
      signal,
    });

    if (!response.ok) {
      let error = await response.json();
      if(error && error?.length > 0){
        return {
          success: false,
          message: error[0].message_client || "El documento no pudo ser generado",
        };
      }
      return {
        success: false,
        message: "El documento no pudo ser generado",
      };
    }
    const data = await response.blob()
    saveAs(data, download.name);
    return {
      success: true,
      message: "",
    };
  } catch (error) {
    console.error(error);
    if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch') {
      console.error(error.message);
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    return false;
  }
}

// Node module

export const getDataNodes = async ({
  page,
  search,
  node,
  signal,
  ...props
}) => {
  try {
    const params = {
      page: page,
      perPage: endpoints?.pagination_size ?? '10', 
      ...(search ? {search: search } : {}),
      ...(node ? {searchfield: 'nodo_id', searchfieldtext: node } : {}),
      ...(props.OrderAscending ? { order: props.OrderAscending === 'asc' ? 'desc' : 'asc'} : {}),
      ...(props.OrderBy ? { sort: props.OrderBy } : {}),
    }
      const respose= await api.get('', {
      meta: {
        endpointKey: 'get_node_info',
        params,
        signal
      }
    });
    const {data} = respose;
    if (data)
      return {
        data: data?.payload.items ?? [],
        success: true,
        message: "Datos obtenidos con éxito",
      };
    else{
      return respose;
    }

  } catch (error) {
    console.error(error);
    if (error.name === "CanceledError") {
      console.log('Request canceled');
      return {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
    }
    return {
      success: false,
      message: error.name === "NETWORK_CONNECTION" ? error.message : "Lo sentimos ocurrió un error",
    };
  }
}

export const downloadNodeInfo = async ({
  page,
  search,
  node,
  download,
  OrderAscending,
  OrderBy,
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const response = await fetch(`${endpoints.get_node_info}?${download.params}${search ? `&search=${search}` : ''}${node ? `&search=${node}` : ''}${OrderAscending  === 'asc' ? `&order=desc` : '&order=asc'}${OrderBy ? `&sort=${OrderBy}` : ''}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (!response.ok) {
      let error = await response.json();
      if(error && error?.length > 0){
        return {
          success: false,
          message: error[0].message_client || "El documento no pudo ser generado",
        };
      }
      return {
        success: false,
        message: "El documento no pudo ser generado",
      };
    }
    const data = await response.blob()
    saveAs(data, download.name);
    return {
      success: true,
      message: "La descarga se ha realizado con éxito"
    };
  } catch (error) {
    if (error.message === "NETWORK_CONNECTION") {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    console.error(error);
    return {
      success: false,
      message: "Lo sentimos ocurrió un error",
    };
  }
}