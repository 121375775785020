import React, { useState } from "react";
import ModalCardPagesSmall from "../Modal/ModalCardPagesSmall";

const SuccessCambioPassword = ({ onClose = () => {} }) => {
  return (
    <ModalCardPagesSmall>
      <div
        className="d-flex flex-column align-items-start col-6 justify-content-evenly "
        style={{ minWidth: "280px" }}
      >
        <h4 className="card_title mt-5">Actualización de contraseña</h4>
        <p className="card_text">
          La actualización de tu contraseña se ha realizado con éxito
        </p>
        <button
          type="button"
          className="button button_active mb-5"
          onClick={onClose}
          data-testid="close-button-success-password-change"
        >
          Cerrar
        </button>
      </div>
    </ModalCardPagesSmall>
  );
};

export default SuccessCambioPassword;
