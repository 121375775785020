import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useRef,
} from "react";
import Table from "../Table/Container";
import { exportBitacoraService, getAllLogsService } from "../../services/api";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import {
  getDefaultInitialDateValues,
  TIME_ALERT,
  useValuesReducer,
} from "../../services/data";
import { handleGetCatalogs, useErrorReducer } from "../../services/data";
import { getCatalogs } from "../../services/api";
import ExportData from "./ExportData";
import FilterForm from "./FilterForm";
import { Permisos } from "../../services/permisos";
import { saveAs } from "file-saver";
import Pagination from "../Table/Pagination";
import usePaginationBack from "../Table/usePaginationBack";
import LimpiarFiltros from "../Sharing/LimpiarFiltros";

dayjs.extend(isoWeek);

export const ActivityContext = createContext();

export const handleExportActivity = async ({
  values,
  service,
  loading,
  error,
  error_message,
  signal,
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    loading();
    const response = await service({ ...values, signal });
    if (!response.success) {
      if (response.name === "AbortError") {
        console.log("Request canceled");
        return;
      }
      loading();
      error("increment", response.message);
      setTimeout(() => {
        error("decrement", response.message);
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    loading();
    return response.data;
  } catch (err) {
    loading();
    if (error.message === "NETWORK_CONNECTION") {
      error(
        "increment",
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        error("decrement", "");
      }, TIME_ALERT);
      return;
    }
    error("increment", err.message);
    setTimeout(() => {
      error("decrement", "");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};
export const handleGetAllLogs = async ({
  values,
  service,
  loading,
  error,
  handleValidateTotalPages,
  setTableData,
  signal,
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    if (signal.current) {
      signal.current.abort();
    }
    signal.current = new AbortController();
    loading();
    const response = await service({
      ...values,
      signal: signal.current.signal,
    });
    if (!response.success) {
      if (response.name === "AbortError") {
        console.log("Request canceled");
      } else {
        error("increment", response.message);
        setTimeout(() => {
          error("decrement", response.message);
        }, TIME_ALERT);
      }
      console.error("Error request");
      return;
    }
    handleValidateTotalPages({
      totalPages: response.pagination.TotalPage,
      page: response.pagination.CurrentPage,
      totalElements: response.pagination.TotalCount,
    });
    setTableData(response.data);
  } catch (err) {
    console.log("🚀 ~ err:", err);
    if (
      err.toString().includes("NETWORK_CONNECTION") ||
      err.toString().includes("Failed to fetch")
    ) {
      error(
        "increment",
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        error("decrement", "");
      }, TIME_ALERT);
    } else {
      error("increment", "Lo sentimos ocurrió un error, intente más tarde");
      setTimeout(() => {
        error("decrement", "");
      }, TIME_ALERT);
    }
    loading();
    if (err.message === "NETWORK_CONNECTION") {
      error(
        "increment",
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        error("decrement", "");
      }, TIME_ALERT);
      return;
    }
    error("increment", "Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error("decrement", "");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  } finally {
    loading();
  }
};

const INITIAL_VALUES = {
  search: "",
  ...getDefaultInitialDateValues(),
  response_time: [],
  type_event: "",
  first_mount: true,
  all_nodes: "",
  all: "",
};
const mappingOrderKeys = {
  usuario: "Usuario",
  fechaHora: "FechaHora",
  tipoEvento: "TipoEvento",
  datosRegistrados: "DatosRegistrados",
};

const base64ToBlob = (base64, mime) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
};

export default function Body() {
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [eventLogsOptions, setEventLogsOptions] = useState([]);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(new AbortController());
  const [orderConfig, setOrderConfig] = useState({
    key: "fechaHora",
    direction: "asc",
  });
  const { errorValues, handleError } = useErrorReducer();
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();

  // Handlers
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const getLogs = async ({ page, ...props }) => {
    handleGetAllLogs({
      values: {
        current_page: page ?? pagination.page,
        limit: true,
        ...values,
        OrderAscending: props?.order || orderConfig.direction || "asc",
        OrderBy: mappingOrderKeys[props?.orderBy || orderConfig.key],
      },
      signal: abortControllerRef,
      loading: handleLoading,
      error: handleError,
      error_message: (msj) => {},
      handleValidateTotalPages,
      setTableData,
      service: getAllLogsService,
    });
  };

  const handleRefreshData = () => {
    setRefreshData((prevState) => !prevState);
  };

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setEventLogsOptions,
      catalog: "event_logs",
      error: handleError,
      error_message: setErrorMessage,
    });
  }, []);

  const handleChange = createHandleChange(handleResetPagination);

  const handleDatePickerChange = createHandleDatePickerChange(
    handleResetPagination
  );

  // Effects
  useEffect(() => {
    getLogs({ page: 1 });
  }, []);

  useEffect(() => {
    getLogs({});
  }, [refreshData, values, orderConfig, pagination.page]);

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  const handleOrderByColumns = async ({ sort_key, order }) => {
    setOrderConfig({ direction: order, key: sort_key });
  };
  const handleExport = async () => {
    console.log(values);
    const data = await handleExportActivity({
      values: {
        limit: false,
        ...values,
        total_registers: pagination.TotalCount,
        OrderAscending: orderConfig.direction ?? "asc",
        OrderBy: mappingOrderKeys[orderConfig.key],
      },
      loading: handleLoading,
      error: handleError,
      error_message: (msj) => {},
      service: getAllLogsService,
      signal: abortControllerRef.current.signal,
    });
    return data;
  };

  const handleDownloadPdf = async () => {
    try {
      const response = await exportBitacoraService({
        current_page: pagination.page,
        search: values.search,
        start_date: values.start_date,
        end_date: values.end_date,
        start_hours: values.start_hours,
        end_hours: values.end_hours,
        limit: false,
        itemsPerPage: "-1",
        PageNumber: "-1",
        signal: abortControllerRef.current.signal,
        total_registers: pagination.TotalCount,
        type_event: values.type_event,
        OrderAscending: orderConfig.direction ?? "asc",
        OrderBy: mappingOrderKeys[orderConfig.key],
      });
      if (!response.success) {
        const errorMessage = response.message || "Error desconocido";
        throw new Error(errorMessage);
      }
      const blobFile = base64ToBlob(response.data, "application/pdf");
      saveAs(blobFile, "Bitacora.pdf");
      setSuccess(true);
      setSuccessMessage("La exportación de datos se ha realizado con éxito");
    } catch (error) {
      console.error("Error en handleDownloadPdf:", error.message);
      handleError("increment", error.message);
      setTimeout(() => {
        handleError("decrement", error.message);
      }, TIME_ALERT);
      setError(true);
      setErrorMessage(error.message || "Ocurrió un error al exportar");
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Usuario",
        selector: (row) => row?.usuario ?? "",
        className: "table_header_cell_activity",
        key_name: "usuario",
        service: handleOrderByColumns,
        style: {
          width: "100%",
          minWidth: "171px",
          maxWidth: "171px",
          paddingLeft: "13px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "171px",
          maxWidth: "171px",
          paddingLeft: "13px",
        },
        cell_classname: "table_row_cell_activity",
      },
      {
        name: "Fecha/Hora",
        selector: (row) =>
          dayjs
            .utc(row?.fechaHora)
            .utcOffset(-6)
            .format("DD/MM/YYYY HH:mm:ss [(GMT-6)]"),
        className: "table_header_cell_activity",
        key_name: "fechaHora",
        service: handleOrderByColumns,
        style: {
          width: "100%",
          minWidth: "140px",
          maxWidth: "169px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "132px",
          maxWidth: "169px",
          textOverflow: "ellipsis",
          textWrap: "nowrap",
        },
        cell_classname: "table_row_cell_activity text-wrap",
      },
      {
        name: "Tipo de evento registrado",
        selector: (row) => row?.tipoEvento ?? "",
        className: "table_header_cell_activity",
        key_name: "tipoEvento",
        service: handleOrderByColumns,
        style: {
          width: "100%",
          minWidth: "244px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "244px",
        },
        cell_classname: "table_row_cell_activity text-wrap",
      },
      {
        name: "Datos registrados",
        selector: (row) => row.datosRegistrados,
        className: "table_header_cell_activity",
        key_name: "datosRegistrados",
        service: handleOrderByColumns,
        style: {
          width: "100%",
          minWidth: "436px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "436px",
        },
        cell_classname: "table_row_cell_activity text-wrap",
      },
    ],
    [handleOrderByColumns]
  );
  // Constants
  const memoizedActivityTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        header_styles={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    pagination,
    orderConfig,
  ]);

  return (
    <ActivityContext.Provider
      value={{
        eventLogsOptions,
        handleExport,
        handleDownloadPdf,
      }}
    >
      <div className="container-fluid d-flex flex-column p-0 dco_actividad_table_container">
        <div
          style={{ marginBottom: "11px" }}
          className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end pt-3"
        >
          {Permisos.verificarPermiso("Bitácora", "Buscar") && (
            <FilterForm
              handleDatePickerChange={handleDatePickerChange}
              handleChange={handleChange}
              values={values}
            />
          )}
          {Permisos.verificarPermiso("Bitácora", "Exportar") && (
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap">
              <LimpiarFiltros
                handleChange={handleChange}
                INITIAL_VALUES={INITIAL_VALUES}
              />
              <ExportData />
            </div>
          )}
        </div>
        {memoizedActivityTable}
      </div>
      {loading && <Loader />}
      {errorValues.error !== 0 && (
        <AlertaGeneral type={"error"}>{errorValues.message}</AlertaGeneral>
      )}
    </ActivityContext.Provider>
  );
}
