import React, { useCallback } from "react";

export default function Header({
  columns,
  header_styles,
  header_classname,
  handleSort,
  sortConfig,
}) {
  const handleColumnClick = useCallback(
    async ({ key_name, service, order }) => {
      if (key_name) {
        if (service) await service({ sort_key: key_name, order });
        handleSort(key_name);
      }
    },
    [handleSort]
  );

  return (
    <thead>
      <tr
        {...(header_classname && { className: header_classname })}
        {...(header_styles && { style: header_styles })}
      >
        {columns.map(({ name, style, className, ...props }, index) => (
          <th
            onClick={() =>
              props.key_name &&
              handleColumnClick({
                key_name: props.key_name,
                service: props.service,
                order: sortConfig.direction,
              })
            }
            scope="col"
            key={`table-header-cell-${index}`}
            {...(className && {
              className: ` ${
                sortConfig?.key === props.key_name
                  ? "dco_column_sort_selected"
                  : ""
              } ${className} ${
                props.key_name
                  ? sortConfig.key === props.key_name
                    ? sortConfig.direction === "asc"
                      ? "sortable_column_asc"
                      : "sortable_column_desc"
                    : "sortable_column_desc"
                  : ""
              }`,
            })}
            {...(style && { style })}
          >
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );
}
