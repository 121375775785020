import { useCallback, useEffect, useRef } from 'react';

const useOutsideClick = ({ containerRef, setIsOpen }) => {
  const targetRef = useRef(null);

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        targetRef.current &&
        containerRef.current &&
        !targetRef.current.contains(event.target)
      ) {
        if (!containerRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    },
    [containerRef, targetRef, setIsOpen],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return {
    targetRef
  };
};

export default useOutsideClick;
