import { useState } from "react";

const useIsOpen = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    setIsOpen,
    handleClick
  }
};

export default useIsOpen;