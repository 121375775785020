import React, { useState, useEffect, useRef, createContext } from "react";
import Tooltip from "./Tooltip";

export const InputToggleContext = createContext();
const useRequired = ({ required = false, regex = false, value = "" }) => {
  const [messageTooltip, setMessageTooltip] = useState("");
  const [showTooltip, setShowTooltip] = useState("");
  const [validInput, setValidInput] = useState(true);
  const [valorInput, setValorInput] = useState("");
  const focusedStart = useRef(false);
  useEffect(() => {
    if (value) {
      setValorInput(value);
    }
  }, []);

  const handleFocusRequired = (focused) => {
    if (!required) return;
    if (!focusedStart.current && focused) {
      focusedStart.current = true;
    } else if (!valorInput && focusedStart.current && !focused) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  };
  const handleChangeValue = (value) => {
    if (!required) return;
    const valorInputCurrent = value;
    setValorInput(valorInputCurrent);
    if (regex?.length > 0) {
      for (const element of regex) {
        let reg = new RegExp(element.value);
        if (
          element.coincidencia
            ? !reg.test(valorInputCurrent)
            : reg.test(valorInputCurrent)
        ) {
          setShowTooltip(true);
          setMessageTooltip(element.message);
          setValidInput(false);
          return;
        }
      }
    }
    setShowTooltip(false);
    setValidInput(true);
    setMessageTooltip("");
  };

  return {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  };
};
export default function InputToggle({
  description,
  label,
  value,
  icon,
  children,
  disabled,
  required = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(disabled);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };
  const {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  } = useRequired({ required, value });

  useEffect(() => {
    handleChangeValue(value);
  }, [value, handleChangeValue]);

  useEffect(() => {
    handleFocusRequired(isOpen);
  }, [isOpen, handleFocusRequired]);
  return (
    <>
      <InputToggleContext.Provider value={{ handleOpen }}>
        {isOpen ? children : null}
        <div
          className={`container-fluid p-0 position-relative ${
            inputDisabled ? `pe-none opacity-50` : ``
          }`}
        >
          <div
            className={`position-absolute pe-none overflow-hidden input_label
                ${
                  value !== ""
                    ? "input_label_outside_input"
                    : "input_label_inside_input"
                }
                `}
          >
            {label}
          </div>
          <button
            type="button"
            style={{ overflowX: "hidden" }}
            className={`input_select  d-flex justify-content-start border border-dark`}
            onClick={handleOpen}
            disabled={inputDisabled}
          >
            <span
              className="overflow-hidden"
              style={{
                color: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
                lineHeight: "inherit",
                textWrap: "nowrap",
              }}
            >
              {description}
            </span>
            {icon ?? (
              <div className="input_toggle_icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </button>
          {showTooltip && (
            <Tooltip valid={validInput} messageIncorrect={messageTooltip} />
          )}
        </div>
      </InputToggleContext.Provider>
    </>
  );
}
