import { useEffect, useMemo, useRef } from 'react';
import { useIsOpen, useUpdatePosition, useOutsideClick } from './hooks';

const useSelect = ({ handleSetValue, id, options, valueKey, value }) => {
  const { isOpen, setIsOpen, handleClick } = useIsOpen();
  const { position, containerRef, updatePosition } = useUpdatePosition();
  const { targetRef } = useOutsideClick({ containerRef, setIsOpen });
  const labelRef = useRef(null);

  const handleChange = (selectedValue) => {
    handleSetValue({ field: id, value: selectedValue });
    setIsOpen(false);
  };

  const selectedOption = useMemo(() => {
    return options?.find((obj) => obj[valueKey] === value) ?? '';
  }, [options, valueKey, value]);
  
  useEffect(() => {
    updatePosition();
  }, [isOpen, updatePosition]);

  return {
    isOpen,
    handleChange,
    handleClick,
    selectedOption,
    containerRef,
    targetRef,
    position,
    labelRef
  };
};

export default useSelect;
