import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useCallback,
  useRef,
} from "react";
import CopyCell from "./CopyCell";
import FilterForm from "./FilterForm";
import Table from "../Table/Container";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import ExportData from "./ExportData";
import Details from "./Details";
import {
  validateEmptyValues,
  handleGetCatalogs,
  getDefaultInitialDateValues,
  useValuesReducer,
  TIME_ALERT,
} from "../../services/data";
import { Permisos } from "../../services/permisos";
import {
  getDataTransactions,
  downloadTransactions,
  getNodeCatalog,
} from "../../services/api";
import utc from "dayjs/plugin/utc";
import usePaginationBack from "../Table/usePaginationBack";
import Pagination from "../Table/Pagination";
import LimpiarFiltros from "../Sharing/LimpiarFiltros";

dayjs.extend(utc);
dayjs.extend(isoWeek);

export const TransaccionContext = createContext();

const INITIAL_VALUES = {
  search: "",
  ...getDefaultInitialDateValues(),
  response_time: [],
  first_mount: true,
  all_nodes: "",
  all: "",
};

export default function Body() {
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [scoreLimits, setScoreLimits] = useState({
    min: 10,
    max: 100,
  });
  const [responseTimeLimits, setResponseTimeLimits] = useState({
    min: 10,
    max: 100,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(null);
  const [nodeCatalog, setNodeCatalog] = useState([]);
  const [orderConfig, setOrderConfig] = useState({
    key: "Fecha_Hora_Transaccion",
    direction: "desc",
  });

  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();
  // Handlers
  const handleLoading = (state) => {
    setLoading(state);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = useCallback((msj, errorValue) => {
    setErrorMessage(msj);
    handleError(errorValue);
  }, []);

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = useCallback((msj) => {
    setSuccessMessage(msj);
  }, []);

  const handleChange = createHandleChange(handleResetPagination);

  const handleDatePickerChange = createHandleDatePickerChange(
    handleResetPagination
  );

  const handleGetTransacions = useCallback(async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      handleLoading(true);
      const noEmptyValues = validateEmptyValues(values);
      const response = await getDataTransactions({
        page: pagination.page,
        ...noEmptyValues,
        signal: abortControllerRef.current.signal,
        OrderAscending: orderConfig.direction,
        OrderBy: orderConfig.key,
        ...(noEmptyValues.all_nodes === "Todos los nodos"
          ? { all_nodes: "" }
          : { all_nodes: noEmptyValues.all_nodes }),
      });
      if (!response.success) {
        if (response.name === "AbortError") {
          console.log("Request canceled");
        } else {
          if (!error) {
            handleErrorMessage(response?.message);
            setTimeout(() => {
              handleErrorMessage("");
            }, TIME_ALERT);
          }
        }
        handleLoading(false);
        setTableData([]);
        return;
      }
      if (!response.data) {
        handleLoading(true);
      } else {
        handleLoading(false);
      }
      setTableData(response.data?.payload?.items?.list ?? []);
      handleValidateTotalPages({
        totalPages: response.data?.payload?.items?.totalPages,
        page: response.data?.payload?.items?.page,
        totalElements: response.data?.payload?.items?.totalElements,
      });
      setScoreLimits({
        min: response.data?.payload?.items?.min_super_score,
        max: response.data?.payload?.items?.max_super_score,
      });
      setResponseTimeLimits({
        max: response.data?.payload?.items?.max_tiempo_respuesta,
        min: response.data?.payload?.items?.min_tiempo_respuesta,
      });
      // handleLoading(false);
      return;
    } catch (error) {
      console.log("Error en el handler");
      console.error(error);
      if (error.name === "AbortError") {
        console.log("Request canceled");
      } else {
        handleErrorMessage("Lo sentimos ocurrió un error");
        setTimeout(() => {
          handleErrorMessage("");
        }, TIME_ALERT);
      }
      handleLoading(false);
      setTableData([]);
      return;
    }
  }, [
    handleErrorMessage,
    handleValidateTotalPages,
    pagination.page,
    values,
    orderConfig,
  ]);

  const availableDownloads = useMemo(
    () => ({
      excel: {
        params: "&formato=xls",
        name: "Transacciones.xls",
      },
      csv: {
        params: "&formato=csv",
        name: "Transacciones.csv",
      },
      pdf: {
        params: "&formato=pdf",
        name: "Transacciones.pdf",
      },
    }),
    []
  );

  const handleDownloadData = useCallback(
    async (selected, handleModal) => {
      try {
        handleLoading(true);
        const response = await downloadTransactions({
          ...values,
          download: availableDownloads[selected],
          OrderAscending: orderConfig.direction,
          OrderBy: orderConfig.key,
        });
        if (!response.success) {
          handleLoading(false);
          handleErrorMessage(response.message, true);
          setTimeout(() => {
            handleErrorMessage("", false);
          }, TIME_ALERT);
          return;
        }
        handleLoading(false);
        handleSuccess();
        handleSuccessMessage(
          "La exportación de datos se ha realizado con éxito"
        );
        setTimeout(() => {
          handleSuccess();
          handleModal();
          handleSuccessMessage("");
        }, TIME_ALERT);
      } catch (error) {
        console.log(error);
        handleLoading(false);
        handleErrorMessage("Lo sentimos ocurrió un error", true);
        setTimeout(() => {
          handleErrorMessage("", false);
        }, TIME_ALERT);
        return;
      }
    },
    [
      availableDownloads,
      handleErrorMessage,
      handleSuccessMessage,
      values,
      orderConfig,
    ]
  );

  useEffect(() => {
    handleGetTransacions();
  }, [handleGetTransacions, pagination.page, values, orderConfig]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  useEffect(() => {
    handleGetCatalogs({
      service: getNodeCatalog,
      setter: setNodeCatalog,
      catalog: "nodes",
    });
  }, []);

  useEffect(() => {
    handleGetCatalogs({
      service: getNodeCatalog,
      setter: setNodeCatalog,
      catalog: "nodes",
    });
  }, []);

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  const columns = useMemo(
    () => [
      {
        name: "Nodo",
        custom_cell: (data) => data?.idNodo ?? "",
        selector: (data) => data?.idNodo ?? "",
        className: "table_header_cell_transactions",
        key_name: "idNodo",
        service: handleOrderByColumns,
        style: {
          minWidth: "125px",
          maxWidth: "125px",
        },
        cell_styles: {
          minWidth: "125px",
          maxWidth: "125px",
        },
        cell_classname: "table_row_cell_management",
      },
      {
        name: "ID transacción",
        custom_cell: (data) =>
          data?.idTransaccion ? <CopyCell text={data.idTransaccion} /> : "",
        selector: (data) => data?.idTransaccion ?? "",
        service: handleOrderByColumns,
        key_name: "idTransaccion",
        className: "table_header_cell_transactions",
        style: {
          minWidth: "155px",
          maxWidth: "155px",
        },
        cell_styles: {
          minWidth: "155px",
          maxWidth: "155px",
        },
        cell_classname: "table_row_cell_management",
      },
      {
        name: "Fecha/Hora",
        service: handleOrderByColumns,
        selector: (data) =>
          dayjs
            .utc(data?.Fecha_Hora_Transaccion)
            .utcOffset(-6)
            .format("DD/MM/YYYY HH:mm:ss [(GMT-6)]"),
        key_name: "Fecha_Hora_Transaccion",
        className: "table_header_cell_transactions",
        style: {
          minWidth: "184px",
          maxWidth: "184px",
        },
        cell_styles: {
          minWidth: "184px",
          maxWidth: "184px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Tiempo de respuesta",
        service: handleOrderByColumns,
        selector: (data) => `${data?.tiempo_respuesta / 1000 ?? ""} seg`,
        key_name: "tiempo_respuesta",
        className: "table_header_cell_transactions",
        style: {
          minWidth: "162px",
          maxWidth: "162px",
        },
        cell_styles: {
          minWidth: "162px",
          maxWidth: "162px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Índice calidad de fotografía",
        custom_cell: (data) =>
          parseFloat(data?.IndiceCalidad_Fotografia ?? 0) ?? "",
        selector: (data) =>
          parseFloat(data?.IndiceCalidad_Fotografia ?? 0) ?? "",
        key_name: "IndiceCalidad_Fotografia",
        service: handleOrderByColumns,
        className: "table_header_cell_transactions",
        style: {
          minWidth: "194px",
          maxWidth: "194px",
        },
        cell_styles: {
          minWidth: "194px",
          maxWidth: "194px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Validación",
        selector: (data) => data.validacion ?? "",
        service: handleOrderByColumns,
        key_name: "validacion",
        className: "table_header_cell_transactions",
        style: {
          minWidth: "115px",
          miaxWidth: "115px",
        },
        cell_styles: {
          minWidth: "115px",
          miaxWidth: "115px",
        },
        cell_classname: "table_row_cell",
      },
    ],
    [handleOrderByColumns]
  );

  const conditionalColumn = useMemo(
    () => [
      {
        name: "Acciones",
        custom_cell: (data) => <Details data={data} />,
        className: "table_header_cell_transactions",
        style: {
          minWidth: "85px",
          maxWidth: "85px",
        },
        cell_styles: {
          minWidth: "85px",
          maxWidth: "85px",
        },
        cell_classname: "table_row_cell",
      },
    ],
    []
  );
  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={[
          ...columns,
          ...(Permisos.verificarPermiso("Transacciones", "Ver detalle")
            ? conditionalColumn
            : []),
        ]}
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        data={dataTable}
        header_classname="table_header"
        header_styles={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    conditionalColumn,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    pagination,
  ]);

  return (
    <TransaccionContext.Provider
      value={{
        dataTable,
        setError,
        setErrorMessage,
        handleDownloadData,
        nodeCatalog,
      }}
    >
      <div className="container-fluid d-flex flex-column p-0">
        <div
          style={{ marginBottom: "11px" }}
          className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
        >
          {Permisos.verificarPermiso("Transacciones", "Buscar") && (
            <FilterForm
              handleDatePickerChange={handleDatePickerChange}
              handleChange={handleChange}
              values={values}
              scoreLimits={scoreLimits}
              responseTimeLimits={responseTimeLimits}
            />
          )}
          {Permisos.verificarPermiso("Transacciones", "Exportar") && (
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap">
              <LimpiarFiltros
                handleChange={handleChange}
                INITIAL_VALUES={INITIAL_VALUES}
              />
              <ExportData />
            </div>
          )}
        </div>
        {memoizedUserTable}
      </div>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </TransaccionContext.Provider>
  );
}
