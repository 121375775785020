import React from 'react';
import { createPortal } from 'react-dom';

const ALERTS = {
  error: 'error_custom_alert',
  success: 'succes_custom_alert',
};

export default function Alert({ type, children, errors }) {
  const formattedText =
    typeof children === 'string'
      ? children.split('\n').map((text, index) => (
          <React.Fragment key={index}>
            {text}
            <br />
          </React.Fragment>
        ))
      : children;
  return createPortal(
    <div
      className='d-flex justify-content-center w-100 position-absolute top-0'
      style={{ marginTop: '28px', zIndex: '214748369' }}
    >
      <div
        className={`alert_container ${ALERTS[type]} custom_alerts d-flex flex-column justify-content-center`}
        role='alert'
      >
        {formattedText}
        {errors && (
          <ul className='error_list_message'>
            {errors.map((element, index) => (
              <li key={`error-list-element-${index}`}>{element}</li>
            ))}
          </ul>
        )}
      </div>
    </div>,
    document.getElementById('root'),
  );
}
