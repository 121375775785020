import React, { useState } from "react";
import FormCorreo from "./FormCorreo";
import FormPassword from "./FormPassword";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import TwoFactor from "./TwoFactor";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { Permisos } from "../../services/permisos.js";

// Services
import {
  validateEmailLogin,
  login,
  login_two_factor,
} from "../../services/api";
import { TIME_ALERT } from "../../services/data.js";

const availableModulesCatalog = {
  Usuario: "/usuarios",
  Ajustes: "/ajustes",
  "Cargas BD": "/gestion",
  Bitácora: "/actividad",
  Transacciones: "/transacciones",
  Dashboard: "/dashboard",
  Nodos: "/nodos",
};

const LoginComponent = () => {
  const [correo, setCorreo] = useState("");
  const [correoValido, setCorreoValidado] = useState(false);
  const [password, setPassword] = useState("");
  const [pedirPassword, setPedirPassword] = useState(false);
  const [error, setError] = useState("");
  const [pedirCodeTwoFactor, setPedirCodeTwoFactor] = useState(false);
  const [userData, setUserData] = useState({});
  const [userLoginData, setUserLoginData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorForButton, setErrorForButton] = useState(false);
  const navigate = useNavigate();
  const [codInvalido, setCodInvalido] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleResetEmail = () => {
    setCorreo("");
    setCorreoValidado(false);
  };

  const getUserData = async () => {
    try {
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      setLoading(true);
      const response = await validateEmailLogin(correo);
      if (!response.success) {
        setLoading(false);
        setError(response.error_message);
        setCorreoValidado(false);
        setTimeout(() => {
          setError("");
        }, TIME_ALERT);
        return;
      }

      const lastPasswordChangedDate = response.data.lastPasswordChangedDate;

      if (lastPasswordChangedDate) {

          const passwordDate = new Date(lastPasswordChangedDate);
          const currentDate = new Date();
      
          const passwordExpirationDate = new Date(passwordDate);
          passwordExpirationDate.setDate(passwordExpirationDate.getDate() + 90);
      
          if (currentDate > passwordExpirationDate) {
              setError(
                "El usuario que estás utilizando, se encuentra bloqueado temporalmente. Por favor contacta a tu administrador o al soporte."
              );
              setLoading(false)
              setCorreoValidado(false);
              setTimeout(() => {
                setError("");
              }, 3500);
          } else {
            setLoading(false);
            setUserData(response.data);
            setPedirPassword(true);
            sessionStorage.setItem(
              "lastPasswordChangedDate",
              response.data.lastPasswordChangedDate
            );
          }
      } else {
          console.log("No se encontró la fecha de cambio de contraseña.");
      }
    } catch (error) {
      if (error.message === "NETWORK_CONNECTION") {
        setError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        setTimeout(() => {
          setError("");
        }, TIME_ALERT);
        return;
      }
      setLoading(false);
      console.log(error);
      setError("Lo sentimos ocurrió un error");
    }
  };

  const validarEmail = (email) => {
    if (email === "") {
      setCorreoValidado(false);
      return;
    }
    const reLargo =
      /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    setCorreoValidado(reLargo.test(email));
  };

  const manejadorCorreo = (e) => {
    setCorreo(e.target.value);
    validarEmail(e.target.value);
  };

  const manejadorPassword = (value) => {
    setPassword(value);
  };

  const pasarPantalla = () => {
    getUserData();
  };

  const cancelar = () => {
    setErrorForButton(false);
    setPedirPassword(false);
  };
  const iniciarSesion = async (props = { reenviar_twofactor: false }) => {
    try {
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      setLoading(true);
      const response = await login({
        email: correo,
        password,
      });
      if (!response.success) {
        setErrorForButton(true);
        if (response.bloqueado) {
          if (response?.contactar_admin) {
            setLoading(false);
            setError(
              "Has excedido el número de intentos máximos de ingreso a tu cuenta. Por seguridad, se ha realizado un bloqueo a tu cuenta. Ponte en contacto con el administrador de tu cuenta para resolver tu caso"
            );
          } else {
            setError(
              "El usuario que estás utilizando, se encuentra bloqueado temporalmente. Por favor espera 15 minutos para volver a intentarlo."
            );
          }
          setTimeout(() => {
            setError("");
          }, TIME_ALERT);
          return;
        }
        setLoading(false);
        setError(response.error_message);
        setTimeout(() => {
          setError("");
        }, TIME_ALERT);
        return;
      }
      if (!userData.twoFactorEnabled) {
        setLoading(false);
        sessionStorage.setItem("tokenType", response.data.tokenType);
        sessionStorage.setItem("accessToken", response.data.accessToken);
        sessionStorage.setItem("expiresIn", response.data.expiresIn);
        sessionStorage.setItem("refreshToken", response.data.refreshToken);
        sessionStorage.setItem("userEmail", correo);
        await inicializarPeticionPermisos();
        const availableModules = Permisos.obtenerModulosDisponibles();
        if (availableModules.includes("Dashboard")) {
          navigate("/dashboard");
          return;
        }
        if (availableModules.includes("Usuario")) {
          navigate("/usuarios");
          return;
        }
        navigate(availableModulesCatalog[availableModules[0]]);
        return;
      }
      if (props?.reenviar_twofactor) {
        setSuccess("El envío del código se ha realizado con éxito");
        setTimeout(() => {
          setSuccess(false);
        }, TIME_ALERT);
      }
      setLoading(false);
      setUserLoginData(response.data);
      setPedirCodeTwoFactor(userData.twoFactorEnabled);
    } catch (error) {
      if (error.message === "NETWORK_CONNECTION") {
        setError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        setTimeout(() => {
          setError("");
        }, TIME_ALERT);
        return;
      }
      setLoading(false);
      console.log(error);
      setError("Lo sentimos ocurrió un error");
    }
    setPedirPassword(false);
  };
  const ingresarTwoFactor = async (code) => {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    setCodInvalido(false);
    try {
      setLoading(true);
      const response = await login({
        email: correo,
        password: password,
        twoFactorCode: code,
      });
      if (!response.success) {
        setCodInvalido(true);
        if (response.bloqueado) {
          if (response?.contactar_admin) {
            setLoading(false);
            setError(
              "Has excedido el número de intentos máximos de ingreso a tu cuenta. Por seguridad, se ha realizado un bloqueo a tu cuenta. Ponte en contacto con el administrador de tu cuenta para resolver tu caso"
            );
          } else {
            setError(
              "El usuario que estás utilizando, se encuentra bloqueado temporalmente. Por favor espera 15 minutos para volver a intentarlo."
            );
          }
          setTimeout(() => {
            setError("");
          }, TIME_ALERT);
          return;
        }
        setLoading(false);
        setError(response.error_message);
        setTimeout(() => {
          setError("");
        }, TIME_ALERT);
        return;
      }
      setLoading(false);
      sessionStorage.setItem("tokenType", response.data.tokenType);
      sessionStorage.setItem("accessToken", response.data.accessToken);
      sessionStorage.setItem("expiresIn", response.data.expiresIn);
      sessionStorage.setItem("refreshToken", response.data.refreshToken);
      sessionStorage.setItem("userEmail", correo);
      await inicializarPeticionPermisos();
      const availableModules = Permisos.obtenerModulosDisponibles();
      if (availableModules.includes("Dashboard")) {
        navigate("/dashboard");
        return;
      }
      if (availableModules.includes("Usuario")) {
        navigate("/usuarios");
        return;
      }
      navigate(availableModulesCatalog[availableModules[0]]);
      return;
    } catch (error) {
      if (error.message === "NETWORK_CONNECTION") {
        setError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        return;
      }
      setLoading(false);
      console.log(error);
      setError("Lo sentimos ocurrió un error");
    }
  };
  const inicializarPeticionPermisos = () => {
    let instancia = Permisos.getInstance();

    let prom = new Promise((resolver, rechazar) => {
      if (!instancia.datosCargados)
        instancia.updateDataFunc = (data) => {
          if (data.OK) resolver();
          else {
            rechazar("Error al obtener Privilegios");
          }
        };
      else resolver();
    });
    return prom;
  };

  return (
    <>
      <div className={`p-0 overflow-hidden`} style={{ maxWidth: "290px" }}>
        {pedirPassword ? (
          <div className="animate__animated animate__fadeInRight">
            <FormPassword
              userData={userData}
              onCancel={cancelar}
              onLogin={iniciarSesion}
              setPasswordProp={manejadorPassword}
              errorForButton={errorForButton}
            />
          </div>
        ) : pedirCodeTwoFactor ? (
          <TwoFactor
            name={userData?.firstName}
            onIngresarButton={ingresarTwoFactor}
            reenviarCodigo={() => {
              iniciarSesion({ reenviar_twofactor: true });
            }}
            codeValid={codInvalido}
          />
        ) : (
          <div className="animate__animated animate__fadeInLeft">
            <FormCorreo
              correoValido={correoValido}
              onClick={pasarPantalla}
              onChange={manejadorCorreo}
              value={correo}
              reset_email={handleResetEmail}
            />
          </div>
        )}
        {error ? <AlertaGeneral type={"error"}>{error}</AlertaGeneral> : null}
        {success ? (
          <AlertaGeneral type={"success"}>{success}</AlertaGeneral>
        ) : null}
      </div>
      {loading && <Loader />}
    </>
  );
};

export default LoginComponent;
