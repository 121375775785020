export class SingletonAPI {
    solicitudesActuales = {};
    responseSolicitudes = {};
    constructor(){
        SingletonAPI.instance = this;
    }
    static getInstance() {
        if (
            !SingletonAPI.instance 
        ) {
            let inst = new SingletonAPI();
            return inst;
        } 
        else {
            return SingletonAPI.instance;
        }
    }
    async saveRequest (response, urlDestination, options, refreshExpire=500){
        let inst;
        let optString = typeof options === 'string'? options : JSON.stringify(options)
        let url = urlDestination + "/?&"+optString;
        try {
            inst = SingletonAPI.getInstance();
            if(inst.responseSolicitudes[url] === false){
                inst.responseSolicitudes[url] = response;
                if(!inst.solicitudesActuales[url]) //Si se perdió promesas por Cancell recuperando la promesa.
                    inst.solicitudesActuales[url] = new Promise(((resolve, reject) => {
                            resolve(inst.responseSolicitudes[url]);
                        }))
                setTimeout(()=>{
                    inst.solicitudesActuales[url]=false;
                    inst.responseSolicitudes[url]=false;
                },refreshExpire)
            }
        } 
        catch (error) {
            inst.solicitudesActuales[url]=false
            console.log(error);
        }
    }
    verifyRequest (urlDestination, options){
        let inst;
        let optString = typeof options === 'string'? options : JSON.stringify(options)
        let url = urlDestination + "/?&"+optString;
        try {
            inst = SingletonAPI.getInstance();
            if(!inst.solicitudesActuales[url]){
                inst.responseSolicitudes[url] = false;
                
                inst.solicitudesActuales[url] = new Promise((resolve, reject) => {
                    const intervalo = setInterval(() => {
                        if (inst.responseSolicitudes[url]) {
                            clearInterval(intervalo);
                            resolve(inst.responseSolicitudes[url]);
                        }
                    }, 500);
                });
                return false;
            }
            else{
                return inst.solicitudesActuales[url];
            }
        } 
        catch (error) {
            inst.solicitudesActuales[url]=false;
            console.log(error);
        }
    }
}