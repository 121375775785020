import React, { useContext } from "react";
import SearchInput from "../Inputs/SearchInput";
import Select from "../Inputs/Select";
import DatePickerInput from "../Inputs/DatePickerInput";
import SlideWrapper from "../Sharing/SlideWrapper";
import { UserContext } from "./Body";
import LimpiarFiltros from "../Sharing/LimpiarFiltros";

const useUserContext = () => useContext(UserContext);

export default function FilterForm({
  handleChange,
  handleDatePickerChange,
  handleSelectChange,
  values,
  INITIAL_VALUES,
}) {
  const { statusOptions, rolOptions, handleRefreshData } = useUserContext();
  return (
    <form
      className="container p-0 justify-content-start d-flex gap-1 user_filter_form"
      onSubmit={(e) => {
        e.preventDefault();
        handleRefreshData();
      }}
    >
      <SlideWrapper>
        <div style={{ maxWidth: "206px" }} className="container p-0 m-0">
          <SearchInput
            onChange={handleChange}
            value={values.search}
            label="Buscar"
            id="search"
            name="search"
            type="search"
            valid={true}
            rounded={true}
          />
        </div>
        <div
          style={{ maxWidth: "68px" }}
          className="container p-0 m-0"
          data-testid="estaus-select-filter"
        >
          <Select
            id={`status`}
            name={`status`}
            label="Estatus"
            value={values.status}
            handleSetValue={({ field, value }) => {
              handleSelectChange({
                name: field,
                value,
              });
            }}
            options={statusOptions.map(({ name }) => ({
              id: name,
              label: name,
            }))}
            valueKey="id"
            labelKey="label"
            arrowWidth={5}
            rounded={true}
          />
        </div>
        <div
          style={{ maxWidth: "98px" }}
          className="container p-0 m-0"
          data-testid="fecha-select-filter"
        >
          <DatePickerInput
            onChange={handleDatePickerChange}
            value={values.register_date}
            label="Fecha de alta"
            id="register_date"
            valid={true}
            rounded={true}
          />
        </div>
        <div
          style={{ maxWidth: "59px" }}
          className="container p-0 m-0"
          data-testid="roles-select-filter"
        >
          <Select
            id={`rol`}
            name={`rol`}
            label="Roles"
            value={values.rol}
            handleSetValue={({ field, value }) => {
              handleSelectChange({
                name: field,
                value,
              });
            }}
            options={rolOptions.reverse().map(({ name }) => ({
              id: name,
              label: name,
            }))}
            valueKey="id"
            labelKey="label"
            arrowWidth={5}
            rounded={true}
          />
        </div>
      </SlideWrapper>
      <button type="submit" className="d-none"></button>
    </form>
  );
}
