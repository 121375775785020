import React, { useEffect, useState } from "react";

const InputPasswordConfirm = ({
  setPasswordProp = () => {},
  labelPassword = "Contraseña",
  success = false,
  danger = false,
  coincidence = false,
  setBanderaProp = () => {},
}) => {
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [focusInput, setFocusInput] = useState(false);
  const [bandera, setBandera] = useState("blank");

  const manejadorPassword = (e) => {
    setPassword(e.target.value);
    setPasswordProp(e.target.value);
  };
  const alternarMostrarPassword = (e) => {
    setPasswordVisible((prevState) => !prevState);
  };

  useEffect(() => {
    setBanderaProp(bandera);
  }, [bandera]);

  return (
    <div>
      <div
        // className="position-relative mt-3 container-fluid p-0">
        className="position-relative container-fluid p-0"
      >
        <div
          className={`position-absolute pe-none input_label ${
            focusInput
              ? "input_label_outside_input"
              : "input_label_inside_input"
          }`}
        >
          {labelPassword}
        </div>
        <input
          name="password"
          autoComplete="off"
          onChangeCapture={manejadorPassword}
          type={passwordVisible ? "text" : "password"}
          className={`input bg-transparent border-black ${
            danger && "border-danger"
          } ${success && "border-success"}`}
          onFocus={() => {
            setFocusInput(true);
          }}
          onBlur={() => {
            !password && setFocusInput(false);
          }}
          value={password}
          data-testid={labelPassword ?? ""}
        />
        <button
          onClick={alternarMostrarPassword}
          className="input_watch_password_button bg-transparent"
          type="button"
        >
          <img
            src={
              passwordVisible
                ? "/images/dicio/invisible.webp"
                : "/images/dicio/visible.webp"
            }
            alt=""
            width="14"
            height="auto"
          />
        </button>
      </div>
      <div
        style={{ marginTop: "8px" }}
        className="container-fluid mt-1 p-0 d-flex flex-column justify-content-center align-items-center"
      >
        <div className="cont-input-confirm-pass">
          <div className="row container-fluid password_requirements_container">
            <span
              className="col rounded"
              style={{
                maxWidth: "64px",
                height: "4px",
                background: danger
                  ? "#FF0000"
                  : coincidence
                  ? "#0DA60A"
                  : "#D9D9D9",
              }}
            ></span>
          </div>
          <div className="container-fluid p-0 d-flex justify-content-end align-items-center">
            <p className="password_requirements_strenght_label my-0 mx-2">
              {danger
                ? "Coincidencia de contraseña"
                : coincidence
                ? "Coincidencia de contraseña"
                : "Coincidencia de contraseña"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputPasswordConfirm;
