import React from "react";

export default function UserProfilePhoto({ src }) {
  return (
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    <img
      className="rounded rounded-circle border-secondary border-2 mx-auto"
      src={
        src
          ? `data:image/*;base64,${src}`
          : "/images/complete_register/default_user.svg"
      }
      alt={`User profile photo`}
      width={"20px"}
      height={"20px"}
    />
  );
}
