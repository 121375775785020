import React, { Fragment, useEffect } from "react";
// Components
import PageTitle from "../components/Pages/Title";
import PageHeader from "../components/Pages/Header";
import DashboardTemplate from "../components/Dashboard/DashboardTemplate";
import NodoStatisticsData from "../components/Dashboard/NodoStatisticsData";
import ConsorcioStatisticsData from "../components/Dashboard/ConsorcioStatisticsdata";
// Services
import { Permisos } from "../services/permisos";
// Libraries
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  Permisos.refrescarPrivilegios();
  const navigate = useNavigate();
  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };

  useEffect(() => {
    if (!Permisos.verificarPermiso("Dashboard", "Ver la sección")) cerrarSesion();
  }, []);
  return (
    <DashboardTemplate overflowX="hidden">
      <Fragment>
        <PageHeader>
          <PageTitle>Dashboard</PageTitle>
        </PageHeader>
      </Fragment>
      <ConsorcioStatisticsData />
      <NodoStatisticsData />
    </DashboardTemplate>
  );
};

export default Dashboard;
