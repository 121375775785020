import React from "react";

const LimpiarFiltros = ({ INITIAL_VALUES, handleChange }) => {
  const handleLimpiarFiltros = () => {
    for (const key in INITIAL_VALUES) {
      if (Object.prototype.hasOwnProperty.call(INITIAL_VALUES, key)) {
        handleChange({ name: key, value: INITIAL_VALUES[key] });
      }
    }
  };
  return (
    <div>
      <button className="dco_button_limpiar" onClick={handleLimpiarFiltros}>
        Limpiar filtros
      </button>
    </div>
  );
};

export default LimpiarFiltros;
