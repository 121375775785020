import React, { useState, useEffect } from "react";
import OptionButton from "./OptionButton.jsx";
import OptionInterruptor from "./OptionInterruptor.jsx";
import CambioPassword from "./CambioPassword.jsx";
import ModalCardPages from "../Modal/ModalCardPages.jsx";
import SuccessCambioPassword from "./SuccessCambioPassword.jsx";
import AlertaGeneral from "../Alerts/AlertaGeneral.jsx";
import TwoFactor from "./TwoFactor.jsx";
import { getInfoUser } from "../../services/api.js";
import { createBrowserHistory } from "history";
import EditUser from "../Users/EditUser.jsx";
import { Permisos } from "../../services/permisos";
import { TIME_ALERT } from "../../services/data.js";

const ContainerAjustes = () => {
  const [successPassword, setSuccessPassword] = useState(false);
  const [successTwoFactor, setSuccessTwoFactor] = useState(false);
  const [openCambioPassword, setOpenCambioPassword] = useState(false);
  const [emailUser, setEmailUser] = useState("");
  const [error, setError] = useState("");
  const [mostraTwoFactor, setMostraTwoFactor] = useState(false);
  const [infoUser, setInfoUser] = useState(null);
  const [disabledcancelPasswordchange, setDisabledcancelPasswordchange] =
    useState(false);

  const history = createBrowserHistory();

  useEffect(() => {
    let email = sessionStorage.getItem("userEmail");
    setEmailUser(email);
  }, []);

  useEffect(() => {
    if (emailUser && (successTwoFactor || mostraTwoFactor || !infoUser))
      obtenerInfoUser(emailUser);
  }, [mostraTwoFactor, emailUser, successTwoFactor]);

  useEffect(() => {
    if (history.location.state)
      if (history.location.state.changePassword) {
        history.replace("/ajustes", { changePassword: false });
        setDisabledcancelPasswordchange(true);
        abrirCambioPassword();
      }
  }, []);
  const obtenerInfoUser = async (email) => {
    try {
      let result = await getInfoUser(email);
      if (result?.data?.isSucceed) {
        setInfoUser(result?.data?.data?.user);
        setError("");
      }
    } catch (error) {
      setMostraTwoFactor(false);
      if (error?.message) setError(error?.message);
      else setError("Error en el servicio de sesión. Revise su conexión.");
      setTimeout(() => {
        //obtenerInfoUser(emailUser);
        setError("");
      }, TIME_ALERT);
      console.log(error);
    }
  };
  const manejadorResultadoActualizacion = (data) => {
    //setOpenCambioPassword(false);
    if (data.OK) {
      setSuccessPassword(true);
      setOpenCambioPassword(false);
      setDisabledcancelPasswordchange(false);
    } else {
      setError(data.message);
      setTimeout(() => {
        setError("");
      }, TIME_ALERT);
    }
  };
  const cerrarModalTwoFactor = (success) => {
    setMostraTwoFactor(false);
    if (success) setSuccessTwoFactor(true);
    setTimeout(() => {
      setSuccessTwoFactor(false);
    }, TIME_ALERT);
  };
  const abrirCambioPassword = () => {
    setOpenCambioPassword(true);
    setError("");
    setSuccessPassword(false);
  };

  return (
    <>
      <div
        style={{ marginTop: "20px" }}
        className="container-fluid p-4 justify-content-start align-items-strat gap-3 shadow container_info_section section_ajust"
      >
        {Permisos.verificarPermiso("Ajustes", "Actualizar") && (
          <div className="row container-fluid p-0">
            <div className="col-6 d-flex align-items-center first-column">
              <p className="fw-bold m-0" style={{ fontSize: "10px" }}>
                {"Perfil del usuario " + " "}
              </p>
            </div>
            <div className="col-6 col-lg-2 d-flex align-items-center second-column">
              <EditUser button_styles="edit_user_profile_button" type="profile">
                Editar perfil
              </EditUser>
            </div>
          </div>
        )}
        {
          <div className="row container-fluid p-0">
            <OptionButton
              title="Cambio de contraseña"
              subtitle={
                "Última actualización " +
                new Date(
                  sessionStorage.getItem("lastPasswordChangedDate")
                )?.toLocaleDateString()
              }
              buttonContent="Cambiar contraseña"
              onClick={abrirCambioPassword}
            />
          </div>
        }
        {Permisos.verificarPermiso(
          "Ajustes",
          "Doble factor de autenticación"
        ) && (
          <div className="row container-fluid p-0">
            <OptionInterruptor
              title="Doble factor de autenticación"
              subtitle={infoUser?.twoFactorEnabled ? "Activo" : "Inactivo"}
              checked={infoUser?.twoFactorEnabled}
              onClick={() => {
                setMostraTwoFactor(true);
              }}
            />
          </div>
        )}
      </div>
      {openCambioPassword && (
        <CambioPassword
          emailUser={emailUser}
          onActualizar={manejadorResultadoActualizacion}
          onCancel={() => {
            setOpenCambioPassword(false);
          }}
          disabledCancel={disabledcancelPasswordchange}
        />
      )}

      {mostraTwoFactor && infoUser && (
        <TwoFactor
          email={emailUser}
          cerrarModal={cerrarModalTwoFactor}
          activar={!infoUser?.twoFactorEnabled}
          userName={infoUser?.nombre}
        />
      )}
      {successPassword && (
        <SuccessCambioPassword
          onClose={() => {
            setSuccessPassword(false);
          }}
        />
      )}
      {successTwoFactor && (
        <AlertaGeneral type={"success"}>
          Doble factor de autenticación{" "}
          {infoUser?.twoFactorEnabled ? "activado" : "desactivado"}
        </AlertaGeneral>
      )}
      {error && <AlertaGeneral type={"error"}>{error}</AlertaGeneral>}
    </>
  );
};

export default ContainerAjustes;
