import { useCallback, useEffect, useRef, useState } from "react";

const useUpdatePosition = () => {
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const containerRef = useRef(null);

  const updatePosition = useCallback(() => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      setPosition({
        minWidth: containerRect.width,
        left: containerRect.left,
        top: containerRect.top + containerRect.height,
      });
    }
  }, [containerRef]);

  useEffect(() => {
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('hashchange', updatePosition);
    window.addEventListener('orientationchange', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('hashchange', updatePosition);
      window.removeEventListener('orientationchange', updatePosition);
    };
  }, [updatePosition]);

  return {
    position,
    containerRef,
    updatePosition
  }

};

export default useUpdatePosition;