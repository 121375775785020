import React, { useState } from "react";
import { Link } from "react-router-dom";
import Input from "../Inputs/Input";

const FormCorreo = ({
  onChange,
  value,
  onClick,
  correoValido,
  reset_email,
}) => {
  return (
    <div
      className={`row m-0`}
      style={{
        transition: "all 0.4s ease-in-out",
        width: "100%",
        transform: `translateX(0%)`,
      }}
    >
      <div className="col p-0  d-flex flex-column ">
        <p className="fw-bold margin-top-69 margin-bottom-40">
          Captura tus datos para ingresar al panel de control
        </p>
        <Input
          onChange={onChange}
          value={value}
          label="Correo electrónico"
          id="email"
          name="email"
          type="email"
          valid={correoValido}
          delete_button={true}
          reset_input={reset_email}
        />
        <button
          style={{ maxWidth: "fit-content" }}
          className={`button margin-top-60  ${
            correoValido ? "button_active" : "button_disabled"
          }`}
          type="button"
          disabled={!correoValido}
          onClick={onClick}
          data-testid="Siguiente-form-login"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default FormCorreo;
