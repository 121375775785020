import React from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

const SlideWrapper = ({ children }) => {
  return (
    <Swiper
        slidesPerView={'auto'}
        spaceBetween={10}
        navigation={{
          disabledClass: 'swiper_navigation_disabled',
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation]}
        className='mySwiper'
      >
      {React.Children.map(children, (child, index) => (
        <SwiperSlide key={index}>
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SlideWrapper