/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  createContext,
  useMemo,
} from "react";
// Librerías
import dayjs from "dayjs";
import ReportePDF from "dicio-npm-reporte-pdf";
import html2canvas from "html2canvas";
// Funciones
import { Permisos } from "../../services/permisos";
import {
  formatNumberWithCommas,
  handleCapture,
  setRef,
  fetchFallbackImage,
  handleGetCatalogs,
  useValuesReducer,
  toUtcTimeZone,
  getDefaultInitialDateValues,
  TIME_ALERT,
} from "../../services/data";
// Componentes
import Loader from "../Loader/Loader";
import Statistics from "./Statistics";
import PageTitle from "../Pages/Title";
import PageHeader from "../Pages/Header";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import ModalExportDashboard from "./ModalExportDashboard";
import FilterDashboard from "./FilterDashboard";
// Servicios
import {
  getNodeCatalog,
  getDataChartsNodeByDate,
  getStatisticsNodeByDate,
} from "../../services/api";
import TransactionsNodeChart from "../Graficas/Nodes/TransactionsNodeChart";
import TimeAverageNodeChart from "../Graficas/Nodes/TimeAverageNodeChart";
import ResultValidationChart from "../Graficas/Nodes/ResultValidationChart";
import LimpiarFiltros from "../Sharing/LimpiarFiltros";

const widthConversionFactor = 0.361;
const heightConversionFactor = 0.392;

const convertWidth = (pixels) => {
  return pixels * widthConversionFactor;
};

const convertHeight = (pixels) => {
  return pixels * heightConversionFactor;
};

const availableCharts = {
  total_transacciones_nodo: "total_transacciones_nodo",
  tiempo_respuesta_promedio_nodo: "tiempo_respuesta_promedio_nodo",
  promedio_valid_score_nodo: "promedio_valid_score_nodo",
};

const INITIAL_VALUES = {
  ...getDefaultInitialDateValues(),
  node_name: "",
  node_uuid: "",
};

export const NodeDashboardContext = createContext();

export default function NodoStatisticsData() {
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    setFieldValue,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  //Hooks useRef
  const captureRefs = useRef({});
  const contenedorScroll = useRef(null);
  const contenedorGraficas = useRef(null);
  //Hooks useState
  const [padding, setPadding] = useState(5);
  const [loading, setLoading] = useState(false);
  const [propsPDF, setPropsPDF] = useState({});
  const [dataCharts, setDataCharts] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [nodesCatalog, setNodesCatalog] = useState([]);
  const [typeAlert, setTypeAlert] = useState("succes_custom_alert");
  const [alertMessage, setAlertMessage] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [statisticsByDate, setStatisticsByDate] = useState("");
  const [objectIdsDivGraphic, setObjectIdsDivGraphic] = useState({});

  const memorizedNodeCatalog = useMemo(
    () => ({ nodesCatalog }),
    [nodesCatalog]
  );

  const getStatisticsData = useCallback(
    async (values) => {
      if (!values.node_uuid) return;
      let responseDate = await getStatisticsNodeByDate(
        values.node_uuid,
        toUtcTimeZone(
          `${values.start_date} ${
            values.start_hours !== "" ? values.start_hours : "00:00"
          }`
        ).toISOString(),
        toUtcTimeZone(
          `${values.end_date} ${
            values.end_hours !== ""
              ? values.end_hours === "23:59"
                ? `${values.end_hours}:59`
                : values.end_hours
              : "00:00"
          }`
        ).toISOString()
      );
      if (responseDate.success) {
        setStatisticsByDate(responseDate.data[0]);
      } else {
        setStatisticsByDate("");
        responseDate.data.Respuesta
          ? setAlertMessage(responseDate.data.Respuesta)
          : setAlertMessage(
              responseDate.message ??
                "No se encontraron registros para los valores proporcionados, por favor intenta nuevamente."
            );
        setTypeAlert("error");
        setShowAlert(true);
        limpiarError();
      }
    },
    [
      values?.node_uuid,
      values.start_hours,
      values.end_date,
      values.end_hours,
      values.start_hours,
    ]
  );

  const getChartsData = useCallback(
    async (values) => {
      let responseCharts = await getDataChartsNodeByDate(
        values.node_uuid,
        toUtcTimeZone(
          `${values.start_date} ${
            values.start_hours !== "" ? values.start_hours : "00:00"
          }`
        ).toISOString(),
        toUtcTimeZone(
          `${values.end_date} ${
            values.end_hours !== ""
              ? values.end_hours === "23:59"
                ? `${values.end_hours}:59`
                : values.end_hours
              : "00:00"
          }`
        ).toISOString()
      );
      if (responseCharts.success) {
        setDataCharts(responseCharts.data[0]);
      } else {
        setDataCharts("");
        responseCharts?.data?.Respuesta
          ? setAlertMessage(responseCharts?.data?.Respuesta)
          : setAlertMessage(
              responseCharts.message ??
                "No se encontraron registros para los valores proporcionados, por favor intenta nuevamente."
            );
        setTypeAlert("error");
        setShowAlert(true);
        limpiarError();
      }
    },
    [
      values?.node_uuid,
      values.start_hours,
      values.end_date,
      values.end_hours,
      values.start_hours,
    ]
  );

  useEffect(() => {
    handleGetCatalogs({
      service: getNodeCatalog,
      setter: setNodesCatalog,
      catalog: "nodes",
    });
  }, []);

  useEffect(() => {
    setFieldValue("node_uuid", nodesCatalog[0]?.nodo_id ?? "");
    setFieldValue("node_name", nodesCatalog[0]?.nodo_name ?? "");
  }, [nodesCatalog]);

  const promisesData = () => {
    setLoading(true);
    Promise.all([getStatisticsData(values), getChartsData(values)])
      .then((e) => setLoading(false))
      .catch((err) => {
        console.warn(
          `✨ ~ file: NodoStatisticsComponent.jsx:101 ~ useEffect ~ err:`,
          err
        );
        setLoading(false);
        if (
          err.message === "NETWORK_CONNECTION" ||
          err.message === "Failed to fetch"
        )
          setAlertMessage(
            "No hay conexión a Internet. Por favor, verifica tu conexión."
          );
        else {
          setAlertMessage(
            "No se lograron obtener resultados, por favor selecciona nuevamente la fecha"
          );
        }
        setTypeAlert("error");
        setShowAlert(true);
        limpiarError();
      });
  };

  useEffect(() => {
    setLoading(true);
    limpiarGraficas();
    promisesData();
  }, [
    values.end_date,
    values.end_hours,
    values.start_date,
    values.start_hours,
    values.node_uuid,
  ]);

  useEffect(() => {
    let uuid = nodesCatalog?.find
      ? nodesCatalog?.find((node) => node.nodo_name === values.node_name)
          ?.nodo_id
      : false;
    uuid && setFieldValue("node_uuid", uuid);
  }, [values.node_name]);

  const limpiarGraficas = () => {
    setStatisticsByDate("");
    setDataCharts("");
  };

  const limpiarError = () => {
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, TIME_ALERT);
  };

  const exportarGraficasImagen = async () => {
    try {
      // setLoadingExport(true);
      const objetoProvisional = {};
      const objetoProps = {
        numberPages: 0,
        content: [],
      };

      objetoProvisional.configuration = {
        compress: true,
      };
      const transaccionesNodo = formatNumberWithCommas(
        statisticsByDate?.peticionesTransacciones
          ?.peticiones_validacion_identidad ?? 0
      );

      objetoProvisional.texts = {
        titulo: {
          text: "Información dashboard",
          x: convertWidth(29),
          y: convertHeight(64),
          font: {
            fontName: "Mulish",
            fontStyle: "normal",
            fontWeight: 700,
            size: 15,
            textColor: "#22135E",
            lineHeight: 18.83,
          },
        },
        titulo2: {
          text: `/ ${values?.node_name ?? ""}`,
          x: convertWidth(200),
          y: convertHeight(64),
          font: {
            fontName: "Mulish",
            fontStyle: "normal",
            fontWeight: 700,
            size: 15,
            textColor: "#bcbcbc",
            lineHeight: 18.83,
          },
        },
        subtitulo: {
          text: `Nodo ${
            statisticsByDate?.estatusNodo === 1 ? "Activo" : "Inactivo"
          } - Calidad de Datos Optima`,
          x: convertWidth(30),
          y: convertHeight(84),
          font: {
            fontName: "Mulish",
            fontStyle: "normal",
            fontWeight: 700,
            size: 8,
            textColor: "##2D2D2DCC",
            lineHeight: 10,
          },
        },
        fechas: {
          text: `Desde: ${dayjs(
            `${values.start_date} ${
              values.start_hours !== "" ? values.start_hours : "00:00"
            }`
          ).format("DD/MM/YYYY HH:mm")} hrs | Hasta: ${dayjs(
            `${values.end_date} ${
              values.end_hours !== "" ? values.end_hours : "00:00"
            }`
          ).format("DD/MM/YYYY HH:mm")} hrs`,
          x: convertWidth(397),
          y: convertHeight(69),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 6,
            textColor: "#2D2D2DCC",
            lineHeight: 10.04,
          },
        },
        fechaConsulta: {
          text: `Fecha y hora del reporte ${dayjs().format(
            "DD/MM/YYYY HH:mm:ss"
          )} (GMT-6)`,
          x: convertWidth(412),
          y: convertHeight(84),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 6,
            textColor: "#2D2D2DCC",
            lineHeight: 10.04,
          },
        },
        valorRegistrosNodos: {
          text: statisticsByDate?.noRegistros?.toLocaleString() ?? "0",
          x: convertWidth(49),
          y: convertHeight(119),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        registrosNodos: {
          text: `Registros en el nodo`,
          x: convertWidth(33),
          y: convertHeight(130),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorIndiceCalidadRegistro: {
          text: statisticsByDate?.no_Cargas?.toLocaleString() ?? "0",
          x: convertWidth(57),
          y: convertHeight(161),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        indiceCalidadRegistro: {
          text: `Cargas en el nodo`,
          x: convertWidth(32),
          y: convertHeight(171),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorRegistrosConsorcio: {
          text:
            statisticsByDate?.peticionesTransacciones?.peticiones_validacion_identidad?.toLocaleString() ??
            "0",
          x: convertWidth(172),
          y: convertHeight(119),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        transaccionesNodo: {
          text: `Transacciones en el nodo`,
          x: convertWidth(155),
          y: convertHeight(130),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorIndiceCalidadFoto: {
          text: statisticsByDate?.tiempoPromedio?.toLocaleString() ?? "0",
          x: convertWidth(178),
          y: convertHeight(161),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        indiceCalidadFoto: {
          text: `Promedio tiempo de respuesta`,
          x: convertWidth(158),
          y: convertHeight(171),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorNumeroCargasConsorcio: {
          text:
            statisticsByDate?.calidadFotoPromedioValidacion?.toLocaleString() ??
            "0",
          x: convertWidth(297.52),
          y: convertHeight(161),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        numeroCargasConsorcio: {
          text: `Promedio índice calidad de la fotografía`,
          x: convertWidth(279),
          y: convertHeight(171),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorPromedioIndiceRespuestaTiming: {
          text: "seg",
          x: convertWidth(241),
          y: convertHeight(161),
          font: {
            fontName: "Mulish",
            fontStyle: "normal",
            size: 10.02,
            textColor: "#22135E",
            lineHeight: 12.57,
          },
        },
        etiquetaTransacciones: {
          text: `Total de transacciones en el nodo:`,
          x: convertWidth(34.72),
          y: convertHeight(205),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#22135E",
            lineHeight: 10.98,
          },
        },
        valorTotalTransacciones: {
          text: transaccionesNodo,
          x: convertWidth(178),
          y: convertHeight(205),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#2D2D2D",
            lineHeight: 10.98,
          },
        },
        etiquetaTiempoRespuesta: {
          text: `Promedio tiempo de respuesta del nodo:`,
          x: convertWidth(34.72),
          y: convertHeight(379),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#22135E",
            lineHeight: 10.98,
          },
        },
        valorTiempoRespuesta: {
          text: `${
            statisticsByDate?.tiempoPromedio?.toLocaleString() ?? "0"
          } seg`,
          x: convertWidth(201),
          y: convertHeight(379),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#2D2D2D",
            lineHeight: 10.98,
          },
        },
        etiquetaValIDScore: {
          text: `Resultados de la validación`,
          x: convertWidth(34.72),
          y: convertHeight(540),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#22135E",
            lineHeight: 10.98,
          },
        },
      };
      objetoProvisional.rects = {
        rect1: {
          x: convertWidth(24),
          y: convertHeight(100),
          w: convertWidth(115.59),
          h: convertHeight(35.68),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect2: {
          x: convertWidth(147.2),
          y: convertHeight(100),
          w: convertWidth(115.59),
          h: convertHeight(35.68),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },

        rect4: {
          x: convertWidth(24),
          y: convertHeight(141),
          w: convertWidth(115.59),
          h: convertHeight(35.68),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect5: {
          x: convertWidth(147.2),
          y: convertHeight(141),
          w: convertWidth(115.59),
          h: convertHeight(35.68),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect6: {
          x: convertWidth(270.41),
          y: convertHeight(141),
          w: convertWidth(115.59),
          h: convertHeight(35.68),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },

        rect9: {
          x: convertWidth(24),
          y: convertHeight(189.75),
          w: convertWidth(546),
          h: convertHeight(163.71),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect10: {
          x: convertWidth(24),
          y: convertHeight(358),
          w: convertWidth(546),
          h: convertHeight(147.52),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect11: {
          x: convertWidth(24),
          y: convertHeight(511),
          w: convertWidth(546),
          h: convertHeight(188.37),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
      };

      objetoProvisional.images = {
        image1: {
          image: "/images/reportes/pdf/logo_alliance.png",
          format: "",
          x: convertWidth(30),
          y: convertHeight(29),
          width: convertWidth(225.34),
          height: convertHeight(18),
        },
        image2: {
          image: "/images/dashboard/dashboard_db.png",
          format: "",
          x: convertWidth(33.04),
          y: convertHeight(106.66),
          width: convertWidth(11.26),
          height: convertHeight(13.32),
        },
        image3: {
          image: "/images/dashboard/dashboard_transactions.png",
          format: "",
          x: convertWidth(155.29),
          y: convertHeight(108.56),
          width: convertWidth(13.32),
          height: convertHeight(9.35),
        },
        image4: {
          image: "/images/dashboard/dashboard_cargas.png",
          format: "",
          x: convertWidth(34),
          y: convertHeight(148),
          width: 5.00666667 * 3 * widthConversionFactor,
          height: 5.08666667 * 3 * heightConversionFactor,
        },
        image5: {
          image: "/images/icons/clock.png",
          format: "",
          x: convertWidth(157.67),
          y: convertHeight(148.61),
          width: 5.19333333 * 3 * widthConversionFactor,
          height: 5.19333333 * 3 * heightConversionFactor,
        },
        image6: {
          image: "/images/icons/image.png",
          format: "",
          x: convertWidth(278.97),
          y: convertHeight(148.13),
          width: convertWidth(15.58),
          height: convertHeight(15),
        },
        image7: {
          image: await handleCapture(
            availableCharts.total_transacciones_nodo,
            captureRefs,
            fetchFallbackImage
          ),
          format: "",
          x: convertWidth(29),
          y: convertHeight(225.94),
          width: convertWidth(527.42),
          height: convertHeight(106.98),
        },
        image8: {
          image: await handleCapture(
            availableCharts.tiempo_respuesta_promedio_nodo,
            captureRefs,
            fetchFallbackImage
          ),
          format: "",
          x: convertWidth(28),
          y: convertHeight(397),
          width: convertWidth(529),
          height: convertHeight(96),
        },
        image9: {
          image: await handleCapture(
            availableCharts.promedio_valid_score_nodo,
            captureRefs,
            fetchFallbackImage
          ),
          format: "",
          x: convertWidth(28),
          y: convertHeight(561.44),
          width: convertWidth(529),
          height: convertHeight(115.38),
        },
      };
      objetoProps.content.push(objetoProvisional);
      objetoProps.numberPages = objetoProps.numberPages + 1;
      objetoProps.getObjectDocument = getObjectDocument;
      objetoProps.getError = getError;
      setPropsPDF(objetoProps);
    } catch (error) {
      console.warn(error);
    } finally {
      // setLoadingExport(false);
    }
  };

  const getObjectDocument = (doc) => {
    if (doc) {
      setShowAlert(true);
      setTypeAlert("success");
      setAlertMessage("La exportación de datos se ha realizado con éxito.");
      limpiarError();
      doc.save(`Información dashboard_Banco Azteca.pdf`);
      setLoadingExport(false);
      setPropsPDF({});
    } else {
      setShowAlert(true);
      setTypeAlert("error");
      setAlertMessage(
        "Ocurrió un error al momento de generar el reporte, por favor intenta nuevamente"
      );
      limpiarError();
    }
  };

  const getError = (error) => {
    console.log(`🚀 ----------------------------🚀`);
    console.log(`🚀 ~ getError ~ error:`, error);
    console.log(`🚀 ----------------------------🚀`);
  };

  const getIdDivGraphic = (id, type) => {
    setObjectIdsDivGraphic((object) => ({
      ...object,
      [type]: id,
    }));
  };

  const getImageGraphic = async (id) => {
    const div = document.querySelector(`#${id}`);
    // const source = (new XMLSerializer()).serializeToString(div.children[0]);
    const canvasPromise = html2canvas(div, { scale: 4 });
    const canvas = await canvasPromise;
    const imgData = canvas.toDataURL("image/png");
    return imgData;
  };

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  const handleChange = createHandleChange();

  const handleDatePickerChange = createHandleDatePickerChange();

  useEffect(() => {
    window.addEventListener("resize", validateResolution);
    validateResolution();
  }, []);

  const validateResolution = () => {
    let width = window.innerWidth;
    if (width >= 1600) setPadding(105);
    else if (width >= 1400) setPadding(65);
    else {
      setPadding(5);
    }
  };
  return (
    <NodeDashboardContext.Provider value={memorizedNodeCatalog}>
      <div>
        <PageHeader type="title" style={{ paddingLeft: 10 }}>
          <PageTitle type="nodo" nameValue={values?.node_name}>
            Dashboard
          </PageTitle>
        </PageHeader>
        <div id="container_statistics_by_node">
          <Statistics statisticsData={statisticsByDate} />
          <div className="d-flex flex-1 mt-2 mb-4 flex-row justify-content-between dco-dashboard-filtros-container container-fluid p-0 px-3">
            <div
              className="d-flex flex-row align-items-center mt-3"
              style={{ flex: 1 }}
            >
              <div
                className={"dco_graficas-subtitle mx-1"}
                style={{ fontSize: "10px" }}
              >
                Estatus del nodo
              </div>
              <div
                className="dco_graficas-estatus-tag"
                style={
                  statisticsByDate?.estatusNodo === 1
                    ? {}
                    : { backgroundColor: "#a30312" }
                }
              >
                {statisticsByDate?.estatusNodo === 1
                  ? "Activo"
                  : statisticsByDate?.estatusNodo === 0
                  ? "Inactivo"
                  : "Desconocido"}
              </div>
              <div
                className="d-flex"
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: "#05FF00",
                  marginLeft: "19px",
                  marginRight: "7px",
                  border: "2px solid #04B400",
                }}
              ></div>
              <div
                className={"dco_graficas-subtitle"}
                style={{ fontSize: "8.83px" }}
              >
                Índice de cálidad del nodo :{" "}
                {Number(statisticsByDate?.calificacionNodo).toFixed(2) ?? 0}
              </div>
            </div>
            <div
              className="d-flex flex-row align-items-center dco_gracicasDashboard-filtros-busqueda flex-wrap-reverse justify-content-end flex-lg-nowrap justify-content-lg-end"
              style={{ flex: 2, width: "65%" }}
              data-testid="container-filtros-nodos"
            >
              {Permisos.verificarPermiso("Dashboard", "Buscar") && (
                <div style={{ width: "72%" }}>
                  <FilterDashboard
                    {...{
                      handleDatePickerChange,
                      handleChange,
                      values,
                      type: "node",
                    }}
                  />
                </div>
              )}
              {Permisos.verificarPermiso("Dashboard", "Exportar") && (
                <div className="my-1 mt-3 mx-1 justify-content-end d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <LimpiarFiltros
                      handleChange={handleChange}
                      INITIAL_VALUES={INITIAL_VALUES}
                    />
                  </div>
                  <ModalExportDashboard
                    exportarGraficasImagen={exportarGraficasImagen}
                    setLoading={setLoadingExport}
                    setTypeAlert={setTypeAlert}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            id="chart_container"
            ref={contenedorScroll}
            className="container-fluid d-flex dashboard_chart_container px-3"
            style={{
              columnGap: "10px",
              rowGap: "10px",
              padding: "2px",
              overflowY: "scroll",
              overflowX: "hidden",
              flexFlow: "wrap",
            }}
          >
            <TransactionsNodeChart
              widthGeneral="100%"
              heightGeneral="fit-content"
              refKey="total_transacciones_nodo"
              setRef={(key, el) => setRef(key, el, captureRefs)}
              size={{
                height: 219,
              }}
              padding={{ right: padding }}
              flexFlow="1 0 600px"
              titulo="Total de transacciones en el nodo: "
              resumenNumero={
                statisticsByDate?.peticionesTransacciones
                  ?.peticiones_validacion_identidad ?? 0
              }
              data={dataCharts?.peticiones?.Historicos ?? []}
              getIdGraphic={getIdDivGraphic}
            />
            <TimeAverageNodeChart
              widthGeneral="100%"
              heightGeneral="fit-content"
              refKey="tiempo_respuesta_promedio_nodo"
              setRef={(key, el) => setRef(key, el, captureRefs)}
              size={{
                height: 219,
              }}
              padding={{ right: padding }}
              flexFlow="1 0 600px"
              titulo="Tiempo de respuesta promedio del nodo: "
              resumenNumero={`${
                statisticsByDate?.tiempoPromedio !== 0
                  ? Number(statisticsByDate?.tiempoPromedio).toFixed(2)
                  : 0
              } seg`}
              data={dataCharts?.tiempoPromedio?.Historicos ?? []}
              getIdDivGraphic={getIdDivGraphic}
            />
            <ResultValidationChart
              data={
                [
                  dataCharts?.noEncontrados?.Historicos ?? [],
                  dataCharts?.noCoincidencias?.Historicos ?? [],
                  dataCharts?.coincidencias?.Historicos ?? [],
                ] ?? []
              }
              padding={{ right: padding }}
              widthGeneral="100%"
              heightGeneral="fit-content"
              refKey="promedio_valid_score_nodo"
              setRef={(key, el) => setRef(key, el, captureRefs)}
              size={{ height: 219 }}
              flexFlow="1 0 600px"
              getIdDivGraphic={getIdDivGraphic}
            />
          </div>
        </div>
        <div ref={contenedorGraficas} style={{ display: "none" }}></div>
        {(loading || loadingExport) && <Loader />}
        {showAlert && (
          <AlertaGeneral type={typeAlert}>{alertMessage}</AlertaGeneral>
        )}
        {Object.keys(propsPDF).length > 0 && <ReportePDF {...propsPDF} />}
      </div>
    </NodeDashboardContext.Provider>
  );
}
